// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/activestorage').start();

// popper usage:
// - navbar
require("@popperjs/core")

import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/carousel'

// collapse usage:
// - navbar
import 'bootstrap/js/dist/collapse'

import 'bootstrap/js/dist/dropdown'
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
// import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'
// import 'bootstrap/js/dist/tooltip'

// Load js from ViewComponent components (https://viewcomponent.org) from /app/components/**/*.js
import "../view_components"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('DOMContentLoaded', () => {

  const removeFieldsClickHandler = (e) => {
    const el = e.target;
    el.previousElementSibling.setAttribute("value", 1);
    el.closest('fieldset').style.display = 'none';
    return e.preventDefault();
  }

  const removeFieldsAddClickListener = () => {
    document.querySelectorAll('form a.remove_fields').forEach((el) => {
      el.addEventListener("click", removeFieldsClickHandler);
    });
  }

  removeFieldsAddClickListener();

  document.querySelector('form a.add_fields')?.addEventListener("click", (e) => {
    let el = e.target;
    let time = new Date().getTime();
    let dataId = el.getAttribute("data-id");
    let regexp = new RegExp(dataId, 'g');
    let fields = el.getAttribute("data-fields");
    let newFields = fields.replace(regexp, time);
    el.insertAdjacentHTML("beforebegin", newFields);

    removeFieldsAddClickListener();

    return e.preventDefault();
  });

});


// Support component names relative to this directory:
var componentRequireContext = require.context('components', false);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount)


function formatSSN(value) {
  value = value.replace(/\D/g, '');

  if (value.length > 3 && value.length <= 5) {
    value = value.replace(/(\d{3})(\d{1,2})/, '$1-$2');
  } else if (value.length > 5) {
    value = value.replace(/(\d{3})(\d{2})(\d{1,4})/, '$1-$2-$3');
  } else {
    value = value.replace(/(\d{3})/, '$1');
  }

  return value;
}

function formatSSNInputs() {
  const ssnInputs = document.querySelectorAll('form.simple_form input.social-security-number');

  ssnInputs.forEach(input => {
    input.value = formatSSN(input.value);

    input.addEventListener('input', function (e) {
      e.target.value = formatSSN(e.target.value);
    });
  });
}

// Format the existing values on page load
document.addEventListener('DOMContentLoaded', formatSSNInputs);


import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'

function formatPhoneNumber(value) {
  if (value.includes('(') && !value.includes(')')) {
    return value.replace('(', '');
  }

  let asYouType = new AsYouType('US');

  // use a different formatter for non-US numbers
  if (/\+[^1].*/.test(value)) {
    asYouType = new AsYouType();
  }

  return asYouType.input(value);
}

function formatPhoneNumberInputs() {
  const phoneNumberInputs = document.querySelectorAll('form.simple_form input.phone-number');

  phoneNumberInputs.forEach(input => {
    if (input.value) {
      const phoneNumber = parsePhoneNumber(input.value);
      input.value = phoneNumber.formatNational();
    }

    input.addEventListener('input', (event) => {
      const value = event.target.value;

      event.target.value = formatPhoneNumber(value);
    });
  });
}

document.addEventListener('DOMContentLoaded', formatPhoneNumberInputs);
