import { createAsyncThunk } from '@reduxjs/toolkit';

import { httpGetFormDesc, httpPostFormDesc } from '../isoform/persistence';
import { $formDesc, $formUrl, $serverResourceUrl, RootState } from '../store';
import { isFullUrl, replaceUrlSubdirectory } from '../support';
import * as logger from '../support/logger';
import { setFormDesc } from './form-desc-slice';

export const submitForm = createAsyncThunk(
  'submitForm',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    const formUrl = $formUrl(state);
    const formDesc = $formDesc(state);

    if (formUrl === undefined || formDesc === undefined) {
      logger.warn('missing form url during submit:', formUrl);
      return;
    }

    // race to finish with a fake delay because if form submit is too fast it feels weird
    const [resp] = await Promise.all([
      httpPostFormDesc(formUrl, formDesc),
      new Promise(r => setTimeout(r, 2000)),
    ]);

    dispatch(setFormDesc(resp.formDesc));

    const serverResourceUrl = $serverResourceUrl(getState() as RootState);

    // A successful response that includes a root resource url shall be redirected
    if (resp.successful && serverResourceUrl !== undefined) {
      const toUrl = isFullUrl(serverResourceUrl)
        ? serverResourceUrl
        // if the resource url is just a path fragment, assume its from the same server
        : replaceUrlSubdirectory(formUrl, serverResourceUrl);

      logger.info(`redirecting to ${toUrl}`);
      window.location.href = toUrl;
    }
  },
  {
    idGenerator: () => 'submitForm',
  },
);

export const fetchForm = createAsyncThunk(
  'fetchForm',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    const formUrl = $formUrl(state);

    if (formUrl === undefined) {
      logger.warn('missing form url during fetch form:', formUrl);
      return undefined;
    }

    const resp = await httpGetFormDesc(formUrl);

    dispatch(setFormDesc(resp.formDesc));

    return undefined;
  },
  {
    idGenerator: () => 'fetchForm',
  },
);

