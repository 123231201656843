/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';

import { applyFilters } from '../../filters';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { DataPath, dataPathNodeId, dataValueForInput } from '../../isoform';
import {
  $nodeProps, $nodeState, $nodeType, $nodeValidation, setDataPathBlurred,
  setDataPathFocused, setDataPathTouched, setDataPathValue,
} from '../../store';
import { globalInputHeight, inputBaseMixin, Palette, rem } from '../../styles';
import { isPresent } from '../../support';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

interface InputXProps extends InputProps {
  dataPath: DataPath;
}

const inputStyle: React.CSSProperties = {
  ...inputBaseMixin,
  height: rem(globalInputHeight * 2),
  width: '100%',
};

export const TextareaX: React.FC<InputXProps> = (props) => {
  const nodeId = dataPathNodeId(props.dataPath);

  const { hasProblems } = useAppSelector($nodeValidation)(props.dataPath);
  const { required, label, readonly } = useAppSelector($nodeProps)(props.dataPath);
  const { value, focused } = useAppSelector($nodeState)(props.dataPath);
  const appDispatch = useAppDispatch();
  const type = useAppSelector($nodeType)(props.dataPath);

  const ref = React.createRef<HTMLTextAreaElement>();

  const inputStyleX = {
    ...inputStyle,
    ...hasProblems && {
      borderColor: Palette.Attention,
    },
  };

  const propsX = {
    ...props,
    placeholder: (focused || isPresent(value)) ? undefined : label,
  } as InputProps;

  // @ts-ignore
  delete propsX.dataPath;

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (evt: React.ChangeEvent) => {
    const value = (evt.currentTarget as HTMLTextAreaElement).value;
    const valueFiltered = applyFilters(type, value);
    appDispatch(setDataPathValue(props.dataPath, valueFiltered));
  };

  function handleBlur () {
    appDispatch(setDataPathTouched(props.dataPath));
    appDispatch(setDataPathBlurred(props.dataPath));
  }

  function handleFocus () {
    appDispatch(setDataPathFocused(props.dataPath));
  }

  return (
    <textarea
      id={nodeId}
      name={nodeId}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      ref={ref}
      required={!!required}
      style={inputStyleX}
      type='text'
      value={dataValueForInput(value)}
      readOnly={!!readonly}
      {...propsX}
    />
  );
};
