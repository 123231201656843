// Just a humble function to make falling back from undefined values nicer.
export function fallback (a: any, b: any): any { // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  return (a === undefined) ? b : a;
}

// Given a list of objects of type T with a unique key property,
// query the first object by the specified key name and value,
// then update that object with a callback hook.
// If an object by the specified key doesn't exist, it will be appended to the list.
export function addOrUpdate<T extends Record<string, any>> ( // eslint-disable-line @typescript-eslint/no-explicit-any
  list: T[], // the list of things to add or update
  key: string | number, // the name of the key to match queries by
  query: string | number, // the value that the above key should match
  hook: (obj: T) => T, // a hook called with the added (or updated) object when matched
): T[] {
  // first index all objects by their named key
  const index: Record<typeof key, T> = list.reduce((accu, item) => {
    return {
      ...accu,
      [item[key]]: item,
    };
  }, {});

  // call the hook with a cloned object to recieve any modifications
  const hookResult = hook({ ...index[query] || { [key]: query } });

  // now inject the modifications back into the index, and retrieve the values list
  return Object.values({
    ...index,
    [query]: hookResult,
  });
}
