import React from 'react';

import { NodeProblems, NodeRoot } from '../components';
import { DataPath, dataPathNodeId } from '../isoform';
import { flexCenterMixin } from '../styles';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  flexDirection: 'column',
};

const contentStyle: React.CSSProperties = {
  padding: 0,
  width: '100%',
};

// -----------------------------------------------------------------------------

interface NodeWidgetProps {
  dataPath: DataPath;
  children: React.ReactNode;
}

export const NodeWidget: React.FC<NodeWidgetProps> = ({
  dataPath,
  children,
}) => {
  const nodeId = dataPathNodeId(dataPath);

  return (
    <NodeRoot key={nodeId}>
      <div className='NodeWidget' style={containerStyle}>
        <div style={contentStyle}>
          {children}
        </div>
        <NodeProblems dataPath={dataPath} />
      </div>
    </NodeRoot >
  );
};
