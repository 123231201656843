import React from 'react';

import { FormDesc, FormUrl } from '../isoform/isoform';
import { IsoformEmbedded } from '../isoform/IsoformEmbedded';

interface IsoformBridgeProps {
  formDesc: FormDesc;
  formUrl: FormUrl;
}

export const IsoformBridge: React.FC<IsoformBridgeProps> = ({
  formDesc,
  formUrl,
}) => {
  return (
    <IsoformEmbedded formDesc={formDesc} formUrl={formUrl} />
  );
};

export default IsoformBridge;
