/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';

import { useAppSelector } from '../../hooks';
import { DataPath } from '../../isoform';
import { LabelX } from '../../nodes/support';
import { $nodeState } from '../../store';
import { absolute, Palette } from '../../styles';
import { isPresent } from '../../support';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  position: 'relative',
};

const labelStyle: React.CSSProperties = {
  ...absolute({
    top: 'calc(1rem * (5/12) * -1)',
    left: 'calc(0.75rem - 1px)',
  }),
  fontSize: 'calc(1rem * (9/12))',
  margin: 0,
  padding: 0,
  paddingLeft: '0.25rem',
  paddingRight: '0.25rem',
  backgroundColor: Palette.White,
  color: Palette.Neutral,
  display: 'none', // hidden be default
};

// -----------------------------------------------------------------------------

interface WithFloatingLabelProps {
  dataPath: DataPath;
  children: React.ReactNode;
  labelOverride?: string;
}

export const WithFloatingLabel: React.FC<WithFloatingLabelProps> = ({
  dataPath,
  children,
  labelOverride,
}) => {
  const { focused, value } = useAppSelector($nodeState)(dataPath);
  const hoverLabel = focused || isPresent(value);

  const labelStyleX = {
    ...labelStyle,
    ...hoverLabel && {
      display: 'block',
    },
  };

  return (
    <div style={containerStyle}>
      <LabelX labelOverride={labelOverride} dataPath={dataPath} style={labelStyleX} />
      {children}
    </div>
  );
};

