// Misc helpers
// Break out when the file gets to big

import { JsonObject } from 'type-fest';

import { FormUrl } from '../isoform';
import * as logger from '../support/logger';

export function getFormUrlFromQueryParams (): FormUrl | undefined {
  const urlParam = (new URLSearchParams(window.location.search)).get('url');

  if (urlParam) {
    try {
      // a url string that doesn't blow up is good enough for now
      new URL(urlParam);
      return urlParam;
    } catch (_) {
      logger.warn('invalid form url found in query params:', urlParam);
      return undefined;
    }
  }

  return undefined;
}

// Immutably remove any keys from an object that have undefined as value.
export function removeUndefined (obj: JsonObject): JsonObject {
  const clone = { ...obj };

  for (const key of Object.keys(clone)) {
    if (clone[key] === undefined) {
      delete clone[key];
    }
  }

  return clone;
}

export function isPresent (val: unknown): boolean {
  if (val === undefined) {
    return false;
  }

  if (val === null) {
    return false;
  }

  if (val === '') {
    return false;
  }

  return true;
}

export function replaceUrlSubdirectory (urlA?: string, urlB?: string): string {
  const a = splitUrl(urlA);
  const b = splitUrl(urlB);

  const sub = b.sub?.startsWith('/') ? b.sub : `/${b.sub}`;

  return [a.base, sub].join('');
}

// Split a URL string into is base (protocol, host, port, username, password)
// and its sub (pathname, query, fragment).
// The hacks here are to work around quirks of the js URL API. See tests.
export function splitUrl (url?: string): { base?: string; sub?: string } {
  let base;
  let sub = url === undefined ? '/' : url;

  try {
    const parsed = new URL(url || '');
    // parsed.href will have a trailing slash if original url doesn't
    const hasTrailingSlash = parsed.href === url;

    // capture the sub parts before we mutate
    sub = [
      parsed.pathname = (parsed.pathname === '/' && !hasTrailingSlash) ? '' : parsed.pathname,
      parsed.search,
      parsed.hash,
    ].join('');

    // mutate the parsed url object
    parsed.pathname = '';
    parsed.search = '';
    parsed.hash = '';

    base = parsed.toString().replace(/\/+$/, '');
  } catch {
    // if something goes wrong, we assume the input is the sub
  }

  const clean = (val?: string) => (val === '') ? undefined : val;

  return {
    base: clean(base),
    sub: clean(sub),
  };
}

export function isFullUrl (url?: string): boolean {
  if (url === undefined) {
    return false;
  }
  try {
    return !!(new URL(url)).origin;
  } catch {
    return false;
  }
}
