import * as React from 'react';
import { JsonObject } from 'type-fest';

import { findNode } from '../nodes';
import { removeUndefined } from '../support';
import * as logger from '../support/logger';
import { dataPathNodeId, generateUnknownNode } from './helpers';
import { IsoformNode } from './isoform-node';
import { DataPath, FormDesc, FormDescProps, IsoformNodeProps, NodeId, NodeState } from './types';

// -----------------------------------------------------------------------------

// Turn a FormDesc tree into a ReactElement tree
export function renderFormDesc (
  formDesc: FormDesc,
  ancestors: FormDesc[] = [],
): React.ReactElement {
  const klass = findNode(formDesc.type) as typeof IsoformNode;
  const { component, defaultProps } = klass;

  const children = formDesc.children
    .map(c => renderFormDesc(c, [...ancestors, formDesc]));

  const dataPath = [
    ...ancestors.map(a => a.key),
    formDesc.key,
  ];

  const nodeId = dataPathNodeId(dataPath);

  const props = {
    ...defaultProps,
    ...formDesc.props,
    dataPath,
    dataKey: formDesc.key,
    nodeId,
    key: nodeId,
  } as IsoformNodeProps;

  return React.createElement(component, props, children);
}

// Find the node in given form description by data path.
export function seekFormDesc (formDesc: FormDesc, dataPath: DataPath): FormDesc | undefined {
  const init = { children: [formDesc] } as FormDesc;

  return dataPath.reduce((accu: FormDesc | undefined, key: string) => {
    return accu && accu.children.find(x => x.key === key);
  }, init);
}

// List all children of the given node as data paths
export function childDataPaths (formDesc: FormDesc, dataPath: DataPath): DataPath[] {
  const foundParent = seekFormDesc(formDesc, dataPath);

  if (foundParent === undefined) {
    return [];
  }

  return foundParent.children.map(child => [...dataPath, child.key]);
}

// Depth first search of the form description tree to find the first node that matches the test fn
export function formDescFind (formDesc: FormDesc, testFn: (formDesc: FormDesc) => boolean): FormDesc | undefined {
  if (testFn(formDesc)) {
    return formDesc;
  }

  for (const child of formDesc.children) {
    const result = formDescFind(child, testFn);
    if (result !== undefined) {
      return result;
    }
  }

  return undefined;
}

export function formDescMap (formDesc: FormDesc, hookFn: (formDesc: FormDesc) => FormDesc): FormDesc {
  return {
    ...hookFn(formDesc),
    children: formDesc.children.map(c => formDescMap(c, hookFn)),
  };
}


// Take that filthy foreign JSON data and ensure it's a clean FormDesc
export function launderFormDesc (data: JsonObject): FormDesc {
  const type = data.type?.toString ? data.type.toString() : undefined;
  const key = data.key?.toString ? data.key.toString() : undefined;

  if (data === undefined || type === undefined || key === undefined) {
    return generateUnknownNode();
  }

  const klass = findNode(type) as typeof IsoformNode;

  if (klass === undefined) {
    logger.warn(`No node found by type '${data.type}' during form description laundering. Falling back to unknown node.`);
    return generateUnknownNode();
  }

  const props = removeUndefined({
    ...klass.defaultProps as JsonObject,
    ...(typeof data.props == 'object' ? (data.props as JsonObject) : {}),
  }) as FormDescProps;

  const state = (typeof data.state == 'object' ? (data.state as JsonObject) : {}) as NodeState;

  const possibleChildren = Array.isArray(data.children) && data.children.length > 0
    ? data.children as JsonObject[]
    : [];

  const knownSiblingKeys = new Set();

  const children = possibleChildren.reduce((accu, child) => {
    if (child && child['key'] && knownSiblingKeys.has(child['key'])) {
      logger.warn(`Dropping sibling node with duplicate key '${child.key.toString()}'`);
      return accu;
    }

    knownSiblingKeys.add(child.key);

    return [
      ...accu,
      launderFormDesc(child as JsonObject),
    ];
  }, [] as FormDesc[]);

  return {
    type,
    key,
    props,
    state,
    children,
  };
}

// ----

interface FormDescIndexValue {
  formDesc: FormDesc;
  dataPath: DataPath;
  childNodeIds: NodeId[];
  childPaths: DataPath[];
  parentNodeId: NodeId;
  parentPath: DataPath;
}

// Map needs a NodeId (string) as a key because DataPath (string[]) does not have the right equality in JS
type FormDescIndex = Map<NodeId, FormDescIndexValue>;

export function indexByDataPath (
  formDesc: FormDesc,
  parentPath: DataPath = [],
  map: FormDescIndex = new Map(),
): FormDescIndex {
  const dataPath = [...parentPath, formDesc.key];
  const childPaths = formDesc.children.map(c => [...dataPath, c.key]);

  map.set(dataPathNodeId(dataPath), {
    formDesc,
    dataPath,
    childNodeIds: childPaths.map(dataPathNodeId),
    childPaths,
    parentNodeId: dataPathNodeId(parentPath),
    parentPath,
  });

  for (const child of formDesc.children) {
    indexByDataPath(child, dataPath, map);
  }

  return map;
}
