import React, { CSSProperties } from 'react';

interface NodeRootProps {
  children: React.ReactNode;
}

const containerStyle: CSSProperties = {
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  width: '100%',
};

export const NodeRoot: React.FC<NodeRootProps> = ({
  children,
}) => {
  // TODO set nodeType as className
  return (
    <div className='NodeRoot' style={containerStyle}>
      {children}
    </div>
  );
};
