import React from 'react';

interface ResponsiveSectionProps {
  title: string;
  children: React.ReactNode;
}

export const ResponsiveSection: React.FC<ResponsiveSectionProps> = ({
  title,
  children,
}) => {
  return (
    <section className='ResponsiveSection'>
      <header>
        <h6>{title}</h6>
      </header>
      <article>
        {children}
      </article>
    </section>
  );
};

export default ResponsiveSection;
