// import { hsluvToHex } from 'hsluv';

const yellowHue = 60.6;
const yellowSat = 83.6;
const yellowLit = 83.9;

export const CareSnapYellow = "#f9cb6a"; // hsluvToHex([yellowHue, yellowSat, yellowLit]);

export const Actionable = "#3d8cc3"; // hsluvToHex([yellowHue + 180, yellowSat, yellowLit * (2 / 3)]);
export const ActionableDim = "#a3a3a3"; // hsluvToHex([yellowHue + 180, 0, yellowLit * (10 / 12)]);

export const Focused = CareSnapYellow;

export const Attention = "#de3442"; // hsluvToHex([10, 80, 50]);
export const Neutral = "#777777"; // hsluvToHex([0, 0, 50]);

export const White = "#ffffff"; // hsluvToHex([0, 100, 100]);
export const Black = "#000000"; // hsluvToHex([0, 100, 0]);
export const Gray = "#919191";  // hsluvToHex([0, 0, 60]);
