import React from 'react';

import { NodeCompact } from '../components';
import { FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { SelectX, WithFloatingLabel } from '../nodes/support';

type OptionItem = { value: string; name: string };

interface OpenSelectNodeProps extends IsoformNodeProps {
  placeholder?: string;
  label?: string;
  options?: OptionItem[];
}

const OpenSelectNodeComponent: IsoformNodeComponent<OpenSelectNodeProps> = ({
  options,
  dataPath,
}) => {
  const optionsX = [
    ...(options || []),
    // TODO support "open" by adding a text field
    // { name: '- Other -', value: undefined },
  ];

  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <SelectX
          dataPath={dataPath}
          options={optionsX}
          autoComplete='address-level1'
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class OpenSelectNode extends IsoformNode {
  static nodeName = 'OpenSelect';
  static component = OpenSelectNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
  };
}
