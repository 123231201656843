import React from 'react';

import { useAppSelector } from '../hooks';
import { DataPath } from '../isoform';
import { $nodeServerErrors, $nodeValidation } from '../store';
import { Palette } from '../styles';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  display: 'block',
};

const problemMessageStyle: React.CSSProperties = {
  color: Palette.Attention,
  padding: 0,
  fontSize: 'calc(1rem * (8 / 12))',
  marginTop: 'calc(1rem * (4 / 12))',
};

// -----------------------------------------------------------------------------

interface NodeProblemsProps {
  dataPath: DataPath;
}

export const NodeProblems: React.FC<NodeProblemsProps> = ({
  dataPath,
}) => {
  const { problems } = useAppSelector($nodeValidation)(dataPath);
  const serverErrors = useAppSelector($nodeServerErrors)(dataPath);

  const problemEls = problems.map(problem => (
    <p style={problemMessageStyle} key={problem.key}>{problem.message}</p>
  ));

  const serverErrorEls = serverErrors.map(serverError => (
    <p style={problemMessageStyle} key={serverError.message}>{serverError.message}</p>
  ));

  return (
    <div className='NodeProblems' style={containerStyle}>
      {problemEls}
      {serverErrorEls}
    </div>
  );
};
