import React from 'react';

import { absolute, flexCenterMixin, Palette } from '../styles';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  width: '100%',
  position: 'relative',
};

const containerSpinningStyle: React.CSSProperties = {
  cursor: 'wait',
  position: 'relative',
  userSelect: 'none',
  zIndex: 1,
};

const childrenContainerSpinningStyle: React.CSSProperties = {
  cursor: 'wait',
  filter: 'grayscale(100%) contrast(20%) brightness(150%)',
  transition: '125ms filter linear',
  userSelect: 'none',
  zIndex: 1,
};

const spinnerContainerStyle: React.CSSProperties = {
  cursor: 'wait',
  userSelect: 'none',
  zIndex: 1,
  ...absolute({ top: '0', right: '0', bottom: '0', left: '0' }),
  ...flexCenterMixin,
};

const bgStyle: React.CSSProperties = {
  cursor: 'wait',
  userSelect: 'none',
  zIndex: 2,
  ...absolute({
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  }),
};

// -----------------------------------------------------------------------------

interface SpinBoxProps {
  gate?: boolean;
}

export const SpinBox: React.FC<SpinBoxProps> = ({
  children,
  gate,
}) => {
  const style = {
    ...containerStyle,
    ...gate && containerSpinningStyle,
  };

  const childrenStyle = {
    ...flexCenterMixin,
    ...gate && childrenContainerSpinningStyle,
  };

  const radius = 5;
  const circumference = (radius * 2) * Math.PI;
  const dashArray = `${circumference / 12} ${circumference / 12 / 3}`;

  const spinningEl = gate && (
    <>
      <div className='SpinBox-bg' style={bgStyle} />
      <div className='SpinBox-spinner' style={spinnerContainerStyle}>
        <svg width='4rem' height='4rem' viewBox='0 0 12 12'>
          <circle r={radius} cx='6' cy='6' fill='transparent' stroke={Palette.ActionableDim} strokeWidth='0.5' strokeDasharray={dashArray}>
            <animateTransform
              attributeName='transform'
              type='rotate'
              from='0 6 6'
              to='360 6 6'
              dur='6s'
              repeatCount='indefinite' />
          </circle>
        </svg>
      </div>
    </>
  );

  return (
    <div className='SpinBox' style={style}>
      {spinningEl}
      <div className='SpinBox-children' style={childrenStyle}>
        {children}
      </div>
    </div>
  );
};
