import React from 'react';

import { Button } from '../components';
import { useAppDispatch, useAppSelector } from '../hooks';
import { renderFormDesc } from '../isoform';
import { $anyNodeProblems, $formDesc, $nodeProps, submitForm } from '../store';
import { flexCenterMixin, globalColumnWidth, Palette, rem } from '../styles';

// -----------------------------------------------------------------------------

const sectionMixin: React.CSSProperties = {
  ...flexCenterMixin,
  boxSizing: 'border-box',
  maxWidth: rem(globalColumnWidth),
  width: '100%',
  margin: 0,
  padding: 0,
};

const containerStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
};

const headerStyle: React.CSSProperties = {
  ...sectionMixin,
  justifyContent: 'flex-start',
  display: 'flex',
  marginBottom: rem(1.5),
};

const mainStyle: React.CSSProperties = {
  minHeight: '24rem',
  width: '100%',
  ...sectionMixin,
};

const footerStyle: React.CSSProperties = {
  ...sectionMixin,
  justifyContent: 'flex-end',
  paddingTop: '1rem',
  flexDirection: 'column',
};

const titleStyle: React.CSSProperties = {
  boxSizing: 'border-box',
  fontSize: 'calc(1rem * (16/12))',
  lineHeight: 'calc(1rem * (16/12))',
  margin: 0,
  padding: 0,
};

const problemsNoticeStyle: React.CSSProperties = {
  color: Palette.Attention,
};

// -----------------------------------------------------------------------------

export const FormComponent: React.FC<Record<string, never>> = () => {
  // the description key is equivalent to a data path of one - the root;
  const formDesc = useAppSelector($formDesc);
  const { title } = useAppSelector($nodeProps)([formDesc.key]);
  const anyNodeProblems = useAppSelector($anyNodeProblems);
  const dispatch = useAppDispatch();

  function handleSubmit () {
    // TODO this is touching "removed" children of collections and shouldn't be
    // dispatch(touchAllRequired());
    dispatch(submitForm());
  }

  const problemsNotice = anyNodeProblems && (
    <p style={problemsNoticeStyle}>Please review form problems above.</p>
  );

  return (
    <div className='FormComponent' style={containerStyle}>
      <header style={headerStyle}>
        <h3 style={titleStyle}>{title}</h3>
      </header>
      <main style={mainStyle}>
        {renderFormDesc(formDesc)}
      </main>
      <footer style={footerStyle}>
        {problemsNotice}
        <Button text='Submit' onClick={handleSubmit} disabled={false} augmented />
      </footer>
    </div>
  );
};
