import { CSSProperties } from 'react';

import { rem } from './helpers';
import { Black, Focused, Neutral, White } from './palette';

export const flexCenterMixin: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const globalInputHeight = 2.5;
export const globalInputMargin = 1;
export const globalButtonVerticalPadding = 1;
export const globalColumnWidth = 24;

export const inputBaseMixin: CSSProperties = {
  MozAppearance: 'none',
  WebkitAppearance: 'none',
  appearance: 'none',
  backgroundColor: White,
  border: '1px solid',
  borderColor: Neutral,
  borderRadius: '3px',
  borderWidth: '1px',
  boxSizing: 'border-box',
  color: Black,
  fontFamily: 'inherit',
  fontSize: rem(1),
  height: rem(globalInputHeight),
  lineHeight: rem(1.5),
  outline: 'none',
  padding: rem((globalInputHeight - 1) / 2),
  paddingBottom: rem(1 / 2),
  paddingTop: rem(1 / 2),
  width: '100%',
};

export const legendBaseMixin: CSSProperties = {
  color: Neutral,
  display: 'flex',
  flex: 1,
  fontSize: rem(11 / 12),
  height: rem(1),
  margin: 0,
  marginBottom: rem(globalInputMargin),
  padding: 0,
  textTransform: 'uppercase',
  width: '100%',
  fontWeight: 400,
};

export const focusedBorderMixin = {
  border: '1px solid',
  borderColor: Neutral,
  boxShadow: `0 0 0 2pt ${Focused}`,
};
