import { AnyAction, createReducer } from '@reduxjs/toolkit';

// Types -----------------------------------------------------------------------

export type RequestId = string;

export type RequestsState = Record<RequestId, string>;

const initialState: RequestsState = {};

// Reducer ---------------------------------------------------------------------

function hasRequestId (action: AnyAction) {
  return typeof action?.meta?.requestId == 'string';
}

export const requestsReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(hasRequestId, (state, action) => {
    // TODO avoid accumulation of random request ids
    state[action.meta.requestId] = action.meta.requestStatus;
  });
});
