import React, { useContext } from 'react';

import { hoursDisplay } from '../../support';
import { VisitPlanContext } from './VisitPlanContext';

export const VisitPlanUtilizationBar: React.FC = () => {
  const {
    hoursAuthorized,
    hoursTotal,
    utilizationRatio,
  } = useContext(VisitPlanContext);

  const percent = utilizationRatio * 100;
  const bound = (n: number) => Math.max(0, Math.min(100, n));

  const className = [
    'VisitPlanUtilizationBar',
    (utilizationRatio > 1) && '_over-budget',
  ].filter(x => !!x).join(' ');

  return (
    <div className={className}>
      <div className='_bar' style={{ width: `${bound(percent)}%` }}>
        <span>
          {hoursDisplay(hoursTotal)} Hours
        </span>
      </div>
      <div className='_base' style={{ width: `${bound(100 - percent)}%` }}>
        <span>
          {hoursDisplay(hoursAuthorized)} Authorized
        </span>
      </div>
    </div>
  );
};

export default VisitPlanUtilizationBar;
