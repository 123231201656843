import React from 'react';

import { NodeStandard } from '../components';
import { useNodeHandlers } from '../hooks';
import { dataPathNodeId, FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';

interface LanguageNodeProps extends IsoformNodeProps {
  placeholder?: string;
  label?: string;
}

const LanguageNodeComponent: IsoformNodeComponent<LanguageNodeProps> = ({
  placeholder,
  dataPath,
}) => {
  const nodeId = dataPathNodeId(dataPath);

  const { handleChange, handleBlur } = useNodeHandlers(dataPath);

  const datalistId = `${nodeId}-options`;

  return (
    <NodeStandard dataPath={dataPath}>
      <input
        name={nodeId}
        type='text'
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        list={datalistId}
      />
      <datalist id={datalistId}>
        <option value='English' />
        <option value='Spanish' />
        <option value='German' />
      </datalist>
    </NodeStandard>
  );
};

export class LanguageNode extends IsoformNode {
  static nodeName = 'Language';
  static component = LanguageNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    placeholder: 'English',
    label: 'Language',
  };
}
