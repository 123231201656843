import React from 'react';

import { NodeCompact, Row } from '../components';
import { useNodeHandlers } from '../hooks';
import { dataPathNodeId, FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { InputX, LabelX, WithFloatingLabel } from '../nodes/support';
import { absolute, Palette, rem } from '../styles';

const middleOuterStyle: React.CSSProperties = {
  height: '100%',
  width: rem(1),
  position: 'relative',
};

const middleInnerStyle: React.CSSProperties = {
  ...absolute({
    left: 0,
    right: 0,
    top: '50%',
  }),
  height: '1px',
  backgroundColor: Palette.Neutral,
};

const middleEl = (
  <div style={middleOuterStyle}>
    <div style={middleInnerStyle} />
  </div>
);

// -----------------------------------------------------------------------------

interface DateRangeNodeProps extends IsoformNodeProps {
  label?: string;
}

const DateRangeNodeComponent: IsoformNodeComponent<DateRangeNodeProps> = ({
  dataPath,
}) => {
  const { handleChange, handleBlur } = useNodeHandlers(dataPath);
  const nodeId = dataPathNodeId(dataPath);

  return (
    <NodeCompact dataPath={dataPath}>
      <LabelX dataPath={dataPath} />
      <Row>
        <Row.Item>
          <WithFloatingLabel dataPath={dataPath} labelOverride='From Date'>
            <InputX
              dataPath={dataPath}
              id={nodeId}
              name={nodeId}
              onBlur={handleBlur}
              onChange={handleChange}
              type='date'
              labelOverride='From Date'
            />
          </WithFloatingLabel>
        </Row.Item>
        <Row.Item>
          {middleEl}
        </Row.Item>
        <Row.Item>
          <WithFloatingLabel dataPath={dataPath} labelOverride='To Date'>
            <InputX
              dataPath={dataPath}
              id={nodeId}
              name={nodeId}
              onBlur={handleBlur}
              onChange={handleChange}
              type='date'
              labelOverride='To Date'
            />
          </WithFloatingLabel>
        </Row.Item>
      </Row>
    </NodeCompact>
  );
};

export class DateRangeNode extends IsoformNode {
  static nodeName = 'DateRange';
  static component = DateRangeNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    label: '',
  };
}
