import React from 'react';

import { VisitPlanActionBar } from './VisitPlanActionBar';
import { VisitPlanCaregiverSelect } from './VisitPlanCaregiverSelect';
import { VisitPlanRangeSelect } from './VisitPlanRangeSelect';
import { VisitPlanWeekView } from './VisitPlanWeekView';

// -----------------------------------------------------------------------------

interface VisitPlanSlotContentProps {
  slotId: string;
}

export const VisitPlanSlotContent: React.FC<VisitPlanSlotContentProps> = ({
  slotId,
}) => {
  return (
    <div className='VisitPlanSlotContent'>
      <VisitPlanActionBar slotId={slotId} />
      <VisitPlanCaregiverSelect slotId={slotId} />
      <VisitPlanRangeSelect slotId={slotId} />
      <VisitPlanWeekView slotId={slotId} />
    </div>
  );
};

export default VisitPlanSlotContent;
