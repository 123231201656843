import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import root from 'react-shadow';

import { EmbeddedForm } from './components';
import { FormDesc, FormUrl } from './isoform';
import { setFormContextFormUrl, setFormDesc, store } from './store';
import { Palette } from './styles';

const appStyle: React.CSSProperties = {
  boxSizing: 'border-box',
  fontFamily: 'sans-serif',
};

// styles that can't be set inline or programatically must go here
const globalAppStyle = `
  .App.isoform *::placeholder {
    color: ${Palette.Gray};
  }
`;

interface IsoformEmbeddedProps {
  formDesc?: FormDesc;
  formUrl?: FormUrl;
}

export const IsoformEmbedded: React.FC<IsoformEmbeddedProps> = ({
  formDesc,
  formUrl,
}) => {
  useEffect(() => {
    if (formDesc !== undefined) {
      store.dispatch(setFormDesc(formDesc));
    }

    if (formUrl !== undefined) {
      store.dispatch(setFormContextFormUrl(formUrl));
    }
  }, [formDesc, formUrl]);

  return (
    <root.div className='isoform-shadow-container'>
      <Provider store={store}>
        <style>{globalAppStyle}</style>
        <div className='App isoform' style={appStyle}>
          <EmbeddedForm />
        </div>
      </Provider>
    </root.div>
  );
};

export default IsoformEmbedded;
