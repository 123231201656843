import { CSSProperties } from 'react';

import { removeUndefined } from '../support';

export function absolute (
  { top, right, bottom, left }: Record<string, number | string>,
): CSSProperties {
  return removeUndefined({
    position: 'absolute',
    top: top,
    right: right,
    bottom: bottom,
    left: left,
  });
}

export function rem (val: number): string {
  return `${val}rem`;
}
