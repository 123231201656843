import React from 'react';

import { FormComponent, SpinBox } from '../components';
import { useAppSelector } from '../hooks';
import { $isLoading } from '../store';
import { flexCenterMixin, globalColumnWidth, Palette, rem } from '../styles';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  flexDirection: 'column',
  backgroundColor: Palette.CareSnapYellow,
  minHeight: '100vh',
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
};

const columnPadding = 1.5;

const columnStyle: React.CSSProperties = {
  ...flexCenterMixin,
  width: '100%',
  maxWidth: rem(globalColumnWidth + (columnPadding * 2)),
  boxSizing: 'border-box',
  flexDirection: 'column',
  backgroundColor: Palette.White,
  boxShadow: '0px 0px 8rem rgba(255, 0, 0, 0.125)',
  padding: `2rem ${rem(columnPadding)}`,
  position: 'relative',
};

const contentStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  maxWidth: rem(globalColumnWidth),
};

// -----------------------------------------------------------------------------

export const HostedForm: React.FC<Record<string, never>> = () => {
  const isLoading = useAppSelector($isLoading);

  return (
    <div className='HostedForm' style={containerStyle}>
      <SpinBox gate={isLoading}>
        <div className='HostedForm-column' style={columnStyle}>
          <div className='HostedForm-content' style={contentStyle}>
            <FormComponent />
          </div>
        </div>
      </SpinBox>
    </div>
  );
};
