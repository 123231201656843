import React, { useContext } from 'react';

import { VisitPlanContext } from './VisitPlanContext';
import { VisitPlanServiceAuthorizationSelect } from './VisitPlanServiceAuthorizationSelect';
import { VisitPlanUtilizationBar } from './VisitPlanUtilizationBar';
import { VisitPlanUtilizationPercent } from './VisitPlanUtilizationPercent';

// -----------------------------------------------------------------------------

function safeDate (val?: string) {
  const placeholder = (
    <em>Not Selected</em>
  );

  if (val === undefined) {
    return placeholder;
  }

  return val;
}

// -----------------------------------------------------------------------------

export const VisitPlanServiceAuthorization: React.FC = () => {
  const {
    rootProps,
    serviceAuthorizationSelected,
  } = useContext(VisitPlanContext);

  const { patient } = rootProps;

  const startDateStr = safeDate(serviceAuthorizationSelected?.authStartsAt);
  const endDateStr = safeDate(serviceAuthorizationSelected?.authEndsAt);
  const admittedDateStr = safeDate(patient.admittedDate);

  return (
    <div className='VisitPlanServiceAuthorization'>
      <h5>Service Authorization</h5>
      <dl>
        <div>
          <dt>Authorization</dt>
          <dd>
            <VisitPlanServiceAuthorizationSelect />
          </dd>
        </div>
        <div>
          <dt>Authorization Start Date</dt>
          <dd>{startDateStr}</dd>
        </div>
        <div>
          <dt>Authorization End Date</dt>
          <dd>{endDateStr}</dd>
        </div>
        <div>
          <dt>Patient Admitted to Services</dt>
          <dd>{admittedDateStr}</dd>
        </div>
        <div>
          <dt><strong>Authorization Utilization</strong></dt>
          <dd>
            <div className='_row'>
              <div>
                <VisitPlanUtilizationBar />
              </div>
              <div>
                <VisitPlanUtilizationPercent />
              </div>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default VisitPlanServiceAuthorization;
