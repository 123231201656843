import React from 'react';

import { NodeCompact } from '../components';
import { IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { InputX, WithFloatingLabel } from '../nodes/support';

interface TextNodeProps extends IsoformNodeProps {
  placeholder?: string;
}

const TextNodeComponent: IsoformNodeComponent<TextNodeProps> = ({
  dataPath,
}) => {
  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <InputX
          dataPath={dataPath}
          type='text'
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class TextNode extends IsoformNode {
  static nodeName = 'Text';
  static component = TextNodeComponent;
}
