import { createSelector } from '@reduxjs/toolkit';
import { lensProp, view } from 'rambda';

import {
  DataPath, dataPathNodeId, DataValue, FormDesc, formDescFind, FormDescProps,
  indexByDataPath, IsoformNode, NodeId, NodeState, ServerError,
} from '../isoform';
import { findNode } from '../nodes';
import { RootState } from '../store';
import { presenceValidator, ValidationProblem, Validator } from '../validators';

// -----------------------------------------------------------------------------

export const $formDesc = (state: RootState): FormDesc => state.formDesc;

const $formDescIndex = createSelector($formDesc, x => indexByDataPath(x));

export const $selectNode = createSelector($formDescIndex, (formDescIndex) => {
  return function selectNode (dataPath: DataPath): FormDesc | undefined {
    return formDescIndex.get(dataPathNodeId(dataPath))?.formDesc;
  };
});

export const $isNodeRemoved = createSelector($selectNode, (selectNode) => {
  return function isNodeRemoved (dataPath: DataPath): boolean {
    const node = selectNode(dataPath);
    return !!node?.state.removed;
  };
});

export const $nodeType = createSelector($selectNode, (selectNode) => {
  return function nodeType (dataPath: DataPath): typeof IsoformNode {
    const node = selectNode(dataPath);
    const type = (findNode(node?.type) as typeof IsoformNode);
    return type;
  };
});

export const $nodeProps = createSelector(
  $selectNode, $nodeType,
  (selectNode, nodeType) => {
    return function nodeProps (dataPath: DataPath): FormDescProps {
      const node = selectNode(dataPath);
      const type = nodeType(dataPath);
      return {
        ...type.defaultProps,
        ...node?.props || {},
      };
    };
  },
);

export const $nodeState = createSelector($selectNode, (selectNode) => {
  return function nodeState (dataPath: DataPath): NodeState {
    const node = selectNode(dataPath);
    return node?.state === undefined ? {} : node.state;
  };
});

export const $nodeValue = createSelector($selectNode, (selectNode) => {
  return function nodeValue (dataPath: DataPath): NodeState['value'] {
    const node = selectNode(dataPath);
    return node?.state === undefined ? undefined : node.state.value;
  };
});

export const $nodeServerErrors = createSelector($nodeState, (nodeState) => {
  return function nodeServerErrors (dataPath: DataPath): ServerError[] {
    const state = nodeState(dataPath);
    return state?.server?.errors === undefined ? [] : state.server.errors;
  };
});

export const $collectionVisibleNodeIds = createSelector($formDescIndex, (formDescIndex) => {
  // Given a parent (collection) node id, get a hash with NodeId keys that are either:
  //   - child or any descendant has been touched (state: { touched })
  //   - child has not been removed (state: { removed })
  //   - child has server errors (state: { server: { errors }})
  return function collectionVisibleNodeIds (nodeId: NodeId): Record<NodeId, boolean> {
    const root = formDescIndex.get(nodeId);

    return (root?.childNodeIds || []).reduce((accu, childNodeId) => {
      const childFormDesc = formDescIndex.get(childNodeId)?.formDesc;

      if (!childFormDesc) {
        return accu;
      }

      const anybodyTouchedOrServerErrors = !!formDescFind(childFormDesc, (anybody) => {
        return !!(
          anybody.state.touched || ((anybody.state as NodeState).server?.errors || []).length > 0
        );
      });

      if (!childFormDesc.state.removed || anybodyTouchedOrServerErrors) {
        accu[childNodeId] = true;
      }

      return accu;
    }, {} as Record<NodeId, boolean>);
  };
});

export const $serverResourceUrl = createSelector($formDesc, (formDesc): string | undefined => {
  return view(lensProp('resource_url'), formDesc?.state?.server);
});

// -----------------------------------------------------------------------------

interface NodeValidation {
  hasProblems: boolean;
  problems: ValidationProblem[];
}

export const $nodeValidation = createSelector(
  $nodeProps, $nodeState, $nodeType,
  (nodeProps, nodeState, nodeType) => {
    return function nodeValidation (dataPath: DataPath): NodeValidation {
      const { touched, value } = nodeState(dataPath);
      const { required } = nodeProps(dataPath);
      const type = nodeType(dataPath);

      let problems: ValidationProblem[] = [];

      let validators: Validator[] = type ? [...type.validators] : [];

      // special case if the node is described as required,
      // then add an implied presence validator.
      if (required) {
        validators = [presenceValidator(), ...validators];
      }

      if (touched === true && validators.length > 0) {
        problems = validators.reduce((accu, validator) => {
          return [
            ...accu,
            ...validator(value as DataValue),
          ];
        }, [] as ValidationProblem[]);
      }

      return {
        hasProblems: problems.length > 0,
        problems,
      };
    };
  },
);

export const $anyNodeProblems = createSelector(
  $formDescIndex, $nodeValidation, $nodeServerErrors,
  (formDescIndex, nodeValidation, nodeServerErrors) => {
    for (const val of formDescIndex.values()) {
      if (nodeValidation(val.dataPath).hasProblems || nodeServerErrors(val.dataPath).length > 0) {
        return true;
      }
    }
    return false;
  },
);
