import React from 'react';

import { CloseButton } from '../components';
import { useAppDispatch, useAppSelector } from '../hooks';
import { DataPath } from '../isoform';
import { $isNodeRemoved, removeCollectionItem } from '../store';
import { absolute, globalInputMargin, Palette, rem } from '../styles';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  display: 'flex',
  width: '100%',
};

const closeButtonWrapperStyle: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  width: 'auto',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: 0,
  margin: 0,
  marginLeft: rem(0.75),
};

const childrenContainerStyle: React.CSSProperties = {
  display: 'block',
  position: 'relative',
  width: '100%',
};

const controlsContainerStyle: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

// -----------------------------------------------------------------------------

const lineOuterStyle: React.CSSProperties = {
  position: 'relative',
  width: rem(globalInputMargin),
  height: '100%',
  margin: 0,
  padding: 0,
};

const lineInnerStyle: React.CSSProperties = {
  ...absolute({
    top: 0,
    right: 0,
    bottom: rem(globalInputMargin),
  }),
  width: '1px',
  backgroundColor: Palette.Gray,
};

const lineElStatic = (
  <div style={lineOuterStyle}>
    <div style={lineInnerStyle} />
  </div>
);

// -----------------------------------------------------------------------------

interface CollectionChildWrapperProps {
  dataPath: DataPath;
  // TODO should collection child hide options be removed?
  hideCloseButton?: boolean;
  hideGroupLine?: boolean;
}

export const CollectionChildWrapper: React.FC<CollectionChildWrapperProps> = ({
  dataPath,
  children,
  hideCloseButton,
  hideGroupLine,
}) => {
  const dispatch = useAppDispatch();
  const isRemoved = useAppSelector($isNodeRemoved)(dataPath);

  if (isRemoved) {
    return (<></>);
  }

  function handleClickClose () {
    dispatch(removeCollectionItem(dataPath));
  }

  const closeButtonEl = !hideCloseButton && (
    <div style={closeButtonWrapperStyle}>
      <CloseButton onClick={handleClickClose} />
    </div>
  );

  const lineEl = !hideGroupLine && lineElStatic;

  return (
    <div className='CollectionChildWrapper' style={containerStyle}>
      <div style={childrenContainerStyle}>
        {children}
      </div>
      <div style={controlsContainerStyle}>
        {lineEl}
        {closeButtonEl}
      </div>
    </div >
  );
};
