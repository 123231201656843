import React from 'react';

import { NodeProblems, NodeRoot } from '../components';
import { dataPathNodeId, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { globalInputMargin, rem } from '../styles';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: 'auto',
  marginLeft: rem(globalInputMargin * -0.5),
  marginRight: rem(globalInputMargin * -0.5),
};

const rowChildStyle: React.CSSProperties = {
  width: '100%',
  margin: `0 ${rem(0.5)}`,
};

// -----------------------------------------------------------------------------

interface RowNodeProps extends IsoformNodeProps {
  title?: string;
}

// -----------------------------------------------------------------------------

const RowNodeComponent: IsoformNodeComponent<RowNodeProps> = ({
  nodeId,
  children,
  dataPath,
}) => {
  const childrenWrapped = (children || []).map((child) => (
    <div
      className='Row-child' style={rowChildStyle}
      key={dataPathNodeId(child.props.dataPath)}>
      {child}
    </div>
  ));

  return (
    <NodeRoot key={nodeId}>
      <NodeProblems dataPath={dataPath} />
      <div className='Row-container' style={containerStyle}>
        {childrenWrapped}
      </div>
    </NodeRoot>
  );
};

export class RowNode extends IsoformNode {
  static nodeName = 'Row';
  static component = RowNodeComponent;
}
