import React, { useContext, useMemo, useState } from 'react';

import { fallback, hoursDisplay } from '../../support';
import { LilX, ResponsiveSection } from '../shared';
import {
  Actions,
  MINUTES_UNIT,
  rowHourSum,
  Slot,
  VisitPlanContext,
  VisitPlanRow,
  WEEKDAYS,
} from './VisitPlanContext';


// Hour Select -----------------------------------------------------------------

interface HourSelectProps {
  value?: string | number;
  onChange: (value?: number) => void;
}

export const HourSelect: React.FC<HourSelectProps> = ({
  value,
  onChange,
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  function onBlur () {
    setHasFocus(false);
  }

  function onFocus () {
    setHasFocus(true);
  }

  function handleChange (evt: React.ChangeEvent<HTMLSelectElement>) {
    if (evt.target.value === '') {
      onChange(undefined);
    } else {
      onChange(parseInt(evt.target.value, 10));
    }
  }

  const optionEls = (new Array(24).fill(undefined)).reduce((accu, _, idx) => {
    const offset = 5; // start at 5AM
    const value = (idx + offset) % 24;
    const ampm = (value < 12) ? 'AM' : 'PM';
    const humanValue = (value === 0 || value === 12) ? 12 : value % 12;
    return [...accu, (
      <option key={value} value={value}>
        {`${humanValue} ${ampm}`}
      </option>
    )];
  }, []);

  const hasValue = value !== '' && value !== undefined;
  const mockPlaceholder = ((hasValue === false) && (hasFocus === false));

  const className = [
    'HourSelect',
    hasValue && '_has-value',
    hasFocus && '_has-focus',
    mockPlaceholder && '_mock-placeholder',
  ].filter(x => !!x).join(' ');

  return (
    <select
      className={className}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      value={value === undefined ? '' : value}
    >
      <option value={''}>--</option>
      {optionEls}
    </select>
  );
};


// Minute Select ---------------------------------------------------------------

interface MinuteSelectProps {
  value?: string | number;
  onChange: (value?: number) => void;
}

export const MinuteSelect: React.FC<MinuteSelectProps> = ({
  value,
  onChange,
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  function onBlur () {
    setHasFocus(false);
  }

  function onFocus () {
    setHasFocus(true);
  }

  function handleChange (evt: React.ChangeEvent<HTMLSelectElement>) {
    if (evt.target.value === '') {
      onChange(undefined);
    } else {
      onChange(parseInt(evt.target.value, 10));
    }
  }

  // fill an array to max, compounding every unit.
  const increments = Array.from({ length: (60 / MINUTES_UNIT) }, (_, i) => i * MINUTES_UNIT);
  const optionEls = increments.map((n) => (
    <option key={n} value={n}>{String(n).padStart(2, '0')}</option>
  ));

  const hasValue = value !== '' && value !== undefined;
  const mockPlaceholder = ((hasValue === false) && (hasFocus === false));

  const className = [
    'MinuteSelect',
    hasValue && '_has-value',
    hasFocus && '_has-focus',
    mockPlaceholder && '_mock-placeholder',
  ].filter(x => !!x).join(' ');

  return (
    <>
      <select
        className={className}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        value={fallback(value, '')}
      >
        <option value={''}>--</option>
        {optionEls}
      </select>
    </>
  );
};

// -----------------------------------------------------------------------------

interface DayRowProps {
  day: number;
  slotId: Slot['slotId'];
}

const DayRow: React.FC<DayRowProps> = ({
  day,
  slotId,
}) => {
  const {
    dispatch,
    slot: _slot,
  } = useContext(VisitPlanContext);

  const slot = _slot(slotId);
  const row = useMemo<VisitPlanRow | undefined>(() => {
    return slot?.visitPlanRows.find(row => row.day === day);
  }, [slot, day]);

  const hourSum = rowHourSum(row);

  // ----

  function handleChangeStartHour (hour?: number) {
    dispatch({
      type: Actions.SetPlanRowStart,
      slotId,
      day: day,
      hour,
      minute: row?.startMinute,
    });
  }

  function handleChangeStartMinute (minute?: number) {
    dispatch({
      type: Actions.SetPlanRowStart,
      slotId,
      day: day,
      hour: row?.startHour,
      minute,
    });
  }

  function handleChangeEndHour (hour?: number) {
    dispatch({
      type: Actions.SetPlanRowEnd,
      slotId,
      day: day,
      hour,
      minute: row?.startMinute,
    });
  }

  function handleChangeEndMinute (minute?: number) {
    dispatch({
      type: Actions.SetPlanRowEnd,
      slotId,
      day: day,
      hour: row?.endHour,
      minute,
    });
  }

  const clearDisabled: boolean =
    row?.startHour === undefined
    && row?.startMinute === undefined
    && row?.endHour === undefined
    && row?.endMinute === undefined;

  function handleClickClear () {
    if (clearDisabled) {
      return;
    }

    dispatch({
      type: Actions.ClearPlanRow,
      slotId,
      day: day,
    });
  }

  // ----

  const dayName = WEEKDAYS[day];

  const className = [
    'DayRow',
    hourSum !== undefined && '_has-sum',
  ].filter(x => !!x).join(' ');

  return (
    <div className={className}>
      <span className='_day'>
        {dayName}
      </span>
      <span className='_start'>
        <div className='_label'>In By</div>
        <HourSelect value={row?.startHour} onChange={handleChangeStartHour} />
        <MinuteSelect value={row?.startMinute} onChange={handleChangeStartMinute} />
      </span>
      <span className='_end'>
        <div className='_label'>Out By</div>
        <HourSelect value={row?.endHour} onChange={handleChangeEndHour} />
        <MinuteSelect value={row?.endMinute} onChange={handleChangeEndMinute} />
      </span>
      <span className='_actions'>
        <LilX disabled={clearDisabled} onClick={handleClickClear}/>
      </span>
      <span className='_count'>
        {hoursDisplay(hourSum)}
      </span>
    </div>
  );
};

// -----------------------------------------------------------------------------

interface VisitPlanWeekViewProps {
  slotId: Slot['slotId'];
}

export const VisitPlanWeekView: React.FC<VisitPlanWeekViewProps> = ({
  slotId,
}) => {
  const {
    slot: _slot,
  } = useContext(VisitPlanContext);

  const slot = _slot(slotId);
  const slotTotalHours = (slot?.visitPlanRows || []).reduce((accu, row) => {
    return accu + (rowHourSum(row) || 0);
  }, 0);

  const rows = WEEKDAYS.map((_, day) => (
    <DayRow key={day} slotId={slotId} day={day} />
  ));

  return (
    <div className='VisitPlanWeekView'>
      <ResponsiveSection title='Visit Plan'>
        <div className='_rows'>
          {rows}
        </div>
        <div className='_total'>
          <span className='_label'>
            Caregiver Total Hours:&nbsp;
          </span>
          <span className='_value'>
            {hoursDisplay(slotTotalHours)}
          </span>
        </div>
      </ResponsiveSection>
    </div>
  );
};

export default VisitPlanWeekView;
