import React from 'react';

import { Palette } from '../styles';

// -----------------------------------------------------------------------------

interface PlusGlyphProps {
  active?: boolean;
}

export const PlusGlyph: React.FC<PlusGlyphProps> = () => {
  return (
    <svg viewBox='0 0 1 1' width='1rem' height='1rem'>
      <line x1={1 / 12} y1='0.5' x2={11 / 12} y2='0.5' stroke={Palette.Actionable} strokeWidth={1 / 12} />
      <line x1='0.5' y1={1 / 12} x2='0.5' y2={11 / 12} stroke={Palette.Actionable} strokeWidth={1 / 12} />
    </svg>
  );
};
