import { validate } from 'email-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { isValid } from 'ssn-validator';

import { DataValue } from '../isoform';

export interface ValidationProblem {
  message: string;
  key: string;
}

function msg (message: string, prefix: string): ValidationProblem {
  return {
    key: [prefix, message].join(''),
    message,
  };
}

export type ValidatorProps = Record<string, unknown>;
export type Validator = (val: DataValue) => ValidationProblem[];
export type ValidatorCreator = (props?: ValidatorProps) => Validator;

// -----------------------------------------------------------------------------

export const emailValidator: ValidatorCreator = () => {
  return function (val: DataValue) {
    try {
      if (!validate(val as string)) {
        return [
          msg('Invalid email', 'emailValidator'),
        ];
      }
    } catch {
      return [];
    }

    return [];
  };
};

export const phoneValidator: ValidatorCreator = () => {
  return function (val: DataValue) {
    if (isValidPhoneNumber((val || '').toString(), 'US')) {
      return [];
    }

    return [
      msg('Invalid phone number', 'phoneValidator'),
    ];
  };
};

export const presenceValidator: ValidatorCreator = () => {
  return function (val: DataValue) {
    if (val === '' || val === undefined || val === null) {
      return [
        msg('Required', 'presenceValidator'),
      ];
    }

    return [];
  };
};

export const ssnValidator: ValidatorCreator = () => {
  return function (val: DataValue) {
    if (!isValid(val)) {
      return [
        msg('Invalid SSN', 'ssnValidator'),
      ];
    }

    return [];
  };
};

export const zipCodeValidator: ValidatorCreator = () => {
  return function (val: DataValue) {
    if (val && val.toString().match(
      /^\d{5}(?:[-\s]\d{4})?$/,
    )) {
      return [];
    }

    return [
      msg('Invalid ZIP Code', 'zipCodeValidator'),
    ];
  };
};

