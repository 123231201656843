import React from 'react';

import { NodeCompact } from '../components';
import { FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { InputX, WithFloatingLabel } from '../nodes/support';
import { zipCodeValidator } from '../validators';

interface UsZipCodeNodeProps extends IsoformNodeProps {
  placeholder?: string;
  label?: string;
}

const UsZipCodeNodeComponent: IsoformNodeComponent<UsZipCodeNodeProps> = ({
  dataPath,
}) => {
  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <InputX
          dataPath={dataPath}
          type='text'
          autoComplete='postal-code'
          inputMode='numeric'
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class UsZipCodeNode extends IsoformNode {
  static nodeName = 'UsZipCode';
  static component = UsZipCodeNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    label: 'ZIP Code',
  };

  static validators = [
    zipCodeValidator(),
  ];
}
