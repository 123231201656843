import React from 'react';

import { Button, CollectionChildWrapper, NodeProblems, NodeRoot, PlusGlyph } from '../components';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  dataPathNodeId,
  FormDescProps,
  IsoformNodeComponent,
  IsoformNodeProps,
} from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { $collectionVisibleNodeIds, collectionAppend } from '../store';
import { flexCenterMixin, globalButtonVerticalPadding, legendBaseMixin, rem } from '../styles';
import { isPresent } from '../support';

// -----------------------------------------------------------------------------

const navStyle: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '1rem',
  marginRight: rem(globalButtonVerticalPadding * -1),
};

const titleStyle: React.CSSProperties = {
  ...legendBaseMixin,
};

const buttonInnerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  justifyContent: 'space-evenly',
};

const buttonTextStyle: React.CSSProperties = {
  fontSize: 'calc(1rem * (11/12))',
  lineHeight: 'calc(1rem * (11/12))',
  paddingLeft: '0.5rem',
};


// -----------------------------------------------------------------------------

interface CollectionNodeProps extends IsoformNodeProps {
  button?: string;
  title?: string;
}

const CollectionNodeComponent: IsoformNodeComponent<CollectionNodeProps> = ({
  nodeId,
  children,
  dataPath,
  button,
  title,
}) => {
  const dispatch = useAppDispatch();
  const visibleNodeIds = useAppSelector($collectionVisibleNodeIds)(nodeId);

  function handleAdd () {
    dispatch(collectionAppend(dataPath));
  }

  const childrenToWrap = (children || [])
    .filter(child => visibleNodeIds[child.props.nodeId]);

  const childrenWrapped = childrenToWrap.map((child) => {
    return (
      <CollectionChildWrapper
        key={dataPathNodeId(child.props.dataPath)}
        dataPath={child.props.dataPath}
        hideCloseButton={false}
        hideGroupLine={false}
      >
        {child}
      </CollectionChildWrapper>
    );
  });

  const showAddButton = childrenToWrap.length < (children || []).length;

  const navEl = showAddButton && (
    <nav style={navStyle}>
      <Button diminished borderless onClick={handleAdd}>
        <div style={buttonInnerStyle}>
          <PlusGlyph />
          <span style={buttonTextStyle}>{button || 'Add'}</span>
        </div>
      </Button>
    </nav>
  );

  const titleEl = isPresent(title) && (
    <h3 style={titleStyle}>{title}</h3>
  );

  return (
    <NodeRoot key={nodeId}>
      {titleEl}
      <NodeProblems dataPath={dataPath} />
      <main>
        {childrenWrapped}
      </main>
      {navEl}
    </NodeRoot>
  );
};

export class CollectionNode extends IsoformNode {
  static nodeName = 'Collection';
  static component = CollectionNodeComponent;
  // TODO add 'default state' add add length
  static defaultProps: FormDescProps = {
    button: 'Add Another',
    ...IsoformNode.defaultProps,
  };
}
