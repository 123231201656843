/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';

import { useAppSelector, useNodeHandlers } from '../../hooks';
import { DataPath, dataPathNodeId, DataValue, dataValueForInput } from '../../isoform';
import { $nodeProps, $nodeState, $nodeValidation } from '../../store';
import { focusedBorderMixin, inputBaseMixin, Palette } from '../../styles';
import { isPresent } from '../../support';

export interface SelectXOption {
  value: DataValue;
  name: string;
}

export interface SelectXProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  dataPath: DataPath;
  options: SelectXOption[];
  optionFormat?: (opt: SelectXOption) => string;
}

const selectStyle: React.CSSProperties = {
  ...inputBaseMixin,
};

const optionStyle: React.CSSProperties = {
  color: Palette.Black,
};

export const SelectX: React.FC<SelectXProps> = (props) => {
  const nodeId = dataPathNodeId(props.dataPath);

  const { handleChange, handleBlur, handleFocus } = useNodeHandlers(props.dataPath);
  const { hasProblems } = useAppSelector($nodeValidation)(props.dataPath);
  const { value, focused } = useAppSelector($nodeState)(props.dataPath);
  const { label, readonly } = useAppSelector($nodeProps)(props.dataPath);

  const ref = React.createRef<HTMLSelectElement>();

  const optionFormatX = props.optionFormat || function format (opt: SelectXOption) {
    return opt.name;
  };

  const optionEls = props.options.map((opt: SelectXOption) => {
    const valueX = dataValueForInput(opt.value);
    return (
      <option style={optionStyle} key={valueX.toString()} value={valueX}>{optionFormatX(opt)}</option>
    );
  });

  const selectStyleX = {
    ...selectStyle,
    ...hasProblems && {
      borderColor: Palette.Attention,
    },
    ...(!isPresent(value)) && {
      color: Palette.Neutral,
    },
    ...focused && focusedBorderMixin,
  };

  const propsX = {
    ...props,
    dataPath: undefined,
    options: undefined,
  };

  // @ts-ignore
  delete propsX.dataPath;
  delete propsX.optionFormat;

  return (
    <select
      ref={ref}
      value={dataValueForInput(value)}
      style={selectStyleX}
      id={nodeId}
      name={nodeId}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      disabled={!!readonly}
      {...propsX}
    >
      <option value={''} disabled hidden>{label}</option>
      <option value={''}></option>
      {optionEls}
    </select>
  );
};
