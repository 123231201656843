import { JsonLike } from './types';

// Simulate a normal browser form submit, but with a single payload of JSON data.
// This lets JS components match the conventional Rails request/response strategy,
// instead of fussing with async/XHR requests.
// TODO make this not visible in the page
export function submitForm (method: string, url: string, data: JsonLike, authenticityToken: string): void {
  const legacyMethod = (method || '').toLowerCase() === 'get' ? 'get' : 'post';

  const formEl = document.createElement('form');
  formEl.setAttribute('method', legacyMethod);
  formEl.setAttribute('action', url);

  const methodInputEl = document.createElement('input');
  methodInputEl.setAttribute('name', '_method');
  methodInputEl.setAttribute('value', (method || 'post').toLowerCase());
  formEl.appendChild(methodInputEl);

  const dataInputEl = document.createElement('input');
  dataInputEl.setAttribute('name', '_form_data_as_json');
  dataInputEl.setAttribute('value', JSON.stringify(data));
  formEl.appendChild(dataInputEl);

  const authInputEl = document.createElement('input');
  authInputEl.setAttribute('name', 'authenticity_token');
  authInputEl.setAttribute('value', authenticityToken);
  formEl.appendChild(authInputEl);

  document.body.appendChild(formEl);

  formEl.submit();
}

// Make the browser request a new page with existing url search params extended.
// Setting a param's value to `undefined` will remove it from the query string.
export function submitSearchParams (toMerge: Record<string, string | undefined>) {
  const params = new URLSearchParams(window.location.search);

  for (const [key, value] of Object.entries(toMerge)) {
    if (value === undefined) {
      params.delete(key);
    } else {
      params.set(key, value);
    }
  }

  window.location.search = params.toString();
}
