import React from 'react';

import { flexCenterMixin } from '../styles';

// -----------------------------------------------------------------------------

const rowStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};

interface RowComponent extends React.FC<RowProps> {
  Item: React.FC;
}

interface RowProps {
  children?: React.ReactNode;
}

export const Row: RowComponent = ({
  children,
}) => {
  return (
    <div className='Row' style={rowStyle}>
      {children}
    </div>
  );
};

// -----------------------------------------------------------------------------

const rowItemStyle: React.CSSProperties = {
  ...flexCenterMixin,
  width: 'auto',
};

interface RowItemProps {
  children?: React.ReactNode;
}

export const RowItem: React.FC<RowItemProps> = ({
  children,
}) => {
  return (
    <div className='Row' style={rowItemStyle}>
      {children}
    </div>
  );
};

// -----------------------------------------------------------------------------

Row.Item = RowItem;
