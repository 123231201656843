import React, { useContext } from 'react';

import { VisitPlanContext } from './VisitPlanContext';

export const VisitPlanServiceAuthorizationSelect: React.FC = () => {
  const {
    serviceAuthorizations,
    serviceAuthorizationSelected,
    selectServiceAuthorization,
  } = useContext(VisitPlanContext);

  const value = serviceAuthorizationSelected?.serviceAuthorizationId;

  function handleChange (evt: React.ChangeEvent<HTMLSelectElement>) {
    selectServiceAuthorization(
      evt.currentTarget.value === '' ? undefined : evt.currentTarget.value,
    );
  }

  const options = serviceAuthorizations.map((sa) => (
      <option key={sa.serviceAuthorizationId} value={sa.serviceAuthorizationId} dangerouslySetInnerHTML={{__html: sa.name }}>
    </option>
  ));

  return (
    <div className='VisitPlanServiceAuthorizationSelect'>
      <select value={value || ''} onChange={handleChange}>
        <option value={''} disabled hidden>- Select Service Authorization -</option>
        <option value={''}></option>
        {options}
      </select>
    </div>
  );
};

export default VisitPlanServiceAuthorizationSelect;
