import React from 'react';
import states from 'states-us';

import { NodeCompact } from '../components';
import { FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { SelectX, SelectXOption, WithFloatingLabel } from '../nodes/support';

type StateItem = { value: string; name: string };

interface UsStateNodeProps extends IsoformNodeProps {
  placeholder?: string;
  label?: string;
  states?: StateItem[];
}

const UsStateNodeComponent: IsoformNodeComponent<UsStateNodeProps> = ({
  states,
  dataPath,
}) => {
  function optionFormat (opt: SelectXOption) {
    return `${opt.value} - ${opt.name}`;
  }

  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <SelectX
          dataPath={dataPath}
          options={states || []}
          optionFormat={optionFormat}
          autoComplete='address-level1'
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class UsStateNode extends IsoformNode {
  static nodeName = 'UsState';
  static component = UsStateNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    label: 'State',
    states: states.map((s) => ({ value: s.abbreviation, name: s.name } as StateItem)),
  };
}
