import React from 'react';

import { NodeProblems, NodeRoot } from '../components';
import { DataPath, dataPathNodeId } from '../isoform';
import { globalInputMargin, rem } from '../styles';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  padding: 0,
  paddingBottom: rem(globalInputMargin),
  position: 'relative',
};

// -----------------------------------------------------------------------------

interface NodeCompactProps {
  dataPath: DataPath;
  children: React.ReactNode;
}

export const NodeCompact: React.FC<NodeCompactProps> = ({
  dataPath,
  children,
}) => {
  const nodeId = dataPathNodeId(dataPath);
  return (
    <NodeRoot key={nodeId}>
      <div className='NodeCompact' style={containerStyle}>
        {children}
        <NodeProblems dataPath={dataPath} />
      </div>
    </NodeRoot >
  );
};
