import React, { useContext } from 'react';

import { Actions, VisitPlanContext } from './VisitPlanContext';
import { VisitPlanUtilizationPercent } from './VisitPlanUtilizationPercent';

export const VisitPlanSubmit: React.FC = () => {
  const {
    dispatch,
    isFormValid,
  } = useContext(VisitPlanContext);

  function handleSubmit () {
    dispatch({ type: Actions.Submit });
  }

  return (
    <div className='VisitPlanSubmit'>
      <div className='_spacer_container'/>
      <div className='_button_container'>
        <button
          type='submit'
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          Submit Visit Plan
        </button>
      </div>
      <div className='_utilization_container'>
        <p>
          Authorization Utilization: <VisitPlanUtilizationPercent />
        </p>
      </div>
    </div>
  );
};

export default VisitPlanSubmit;
