import React from 'react';

interface LilXProps {
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
}

export const LilX: React.FC<LilXProps> = ({
  onClick,
  disabled = false,
}) => {

  const classNames = [
    'LilX',
    disabled && '_disabled',
  ].filter(x => !!x).join(' ');

  return (
    <div className={classNames}>
      <a onClick={onClick}></a>
    </div>
  );
};

export default LilX;
