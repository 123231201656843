import React from 'react';

import { NodeProblems, NodeRoot } from '../components';
import { IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { globalInputMargin, legendBaseMixin, rem } from '../styles';

// -----------------------------------------------------------------------------

const fieldsetStyle: React.CSSProperties = {
  padding: 0,
  margin: 0,
  display: 'flex',
  flex: 1,
  width: '100%',
  border: 'none',
  marginBottom: rem(globalInputMargin),
  flexDirection: 'column',
};

const legendStyle: React.CSSProperties = {
  ...legendBaseMixin,
};

const mainStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
};

// -----------------------------------------------------------------------------

interface FieldsetNodeProps extends IsoformNodeProps {
  title?: string;
}

const FieldsetNodeComponent: IsoformNodeComponent<FieldsetNodeProps> = ({
  children,
  dataPath,
  nodeId,
  title,
}) => {
  return (
    <NodeRoot key={nodeId}>
      <fieldset style={fieldsetStyle}>
        <legend style={legendStyle}>{title}</legend>
        <NodeProblems dataPath={dataPath} />
        <main style={mainStyle}>
          {children}
        </main>
      </fieldset>
    </NodeRoot>
  );
};

export class FieldsetNode extends IsoformNode {
  static nodeName = 'Fieldset';
  static component = FieldsetNodeComponent;
}
