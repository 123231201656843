import React from 'react';

import { NodeCompact } from '../components';
import { phoneFilter } from '../filters';
import { FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { InputX, WithFloatingLabel } from '../nodes/support';
import { phoneValidator } from '../validators';

interface UsPhoneNumberNodeProps extends IsoformNodeProps {
  placeholder?: string;
  label?: string;
}

const UsPhoneNumberNodeComponent: IsoformNodeComponent<UsPhoneNumberNodeProps> = ({
  placeholder,
  dataPath,
}) => {
  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <InputX
          dataPath={dataPath}
          type='tel'
          autoComplete='tel'
          placeholder={placeholder}
          inputMode='numeric'
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class UsPhoneNumberNode extends IsoformNode {
  static nodeName = 'UsPhoneNumber';
  static component = UsPhoneNumberNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    placeholder: 'XXX-XXX-XXXX',
    label: 'Phone Number',
  };

  static validators = [
    phoneValidator(),
  ];

  static filters = [
    phoneFilter(),
  ];
}
