import * as React from 'react';

import { Filter } from '../filters';
import { Validator } from '../validators';
import { FormDescProps, IsoformNodeComponent, IsoformNodeProps, NodeName, NodeState } from './types';

// -----------------------------------------------------------------------------

const DefaultComponent: IsoformNodeComponent<IsoformNodeProps> = (props) => {
  return React.createElement('div', {},
    [`DefaultComponent ${props.nodeId}`, props.children]);
};

// We use classes (as opposed to an interface/type) because we need to be able to reference them at runtime.
// Typescript interface types and generics don't actually exist at runtime.
// Having said that, this class should still be stateless and only use static props.

export class IsoformNode {
  static nodeName: NodeName;
  static component: IsoformNodeComponent<IsoformNodeProps> = DefaultComponent;
  static validators: Validator[] = [];
  static filters: Filter[] = [];
  static defaultProps: FormDescProps = {};
  static defaultState: NodeState = {};
}
