import { applyFilters } from '../filters';
import { useAppDispatch, useAppSelector } from '../hooks';
import { DataPath } from '../isoform';
import { $nodeType, setDataPathBlurred, setDataPathFocused, setDataPathTouched, setDataPathValue } from '../store';

interface NodeHandlers {
  handleBlur: React.FocusEventHandler;
  handleChange: React.ChangeEventHandler;
  handleFocus: React.FocusEventHandler;
}

export function useNodeHandlers (dataPath: DataPath): NodeHandlers {
  const type = useAppSelector($nodeType)(dataPath);

  const appDispatch = useAppDispatch();

  function handleBlur () {
    appDispatch(setDataPathTouched(dataPath));
    appDispatch(setDataPathBlurred(dataPath));
  }

  function handleChange (evt: React.ChangeEvent) {
    const value = (evt.currentTarget as HTMLInputElement).value;
    const valueFiltered = applyFilters(type, value);
    appDispatch(setDataPathValue(dataPath, valueFiltered));
  }

  function handleFocus () {
    appDispatch(setDataPathFocused(dataPath));
  }

  return {
    handleBlur,
    handleChange,
    handleFocus,
  };
}
