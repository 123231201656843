import React, { useState } from 'react';

import { submitSearchParams } from '../../support';
import { LilX } from '../shared';

type ParamKey = string;
type RequestParams = Record<ParamKey, string>;

interface PropsItem {
  name: string;
  value: string;
}

interface SelectFilterProps {
  paramKey: ParamKey;
  params: RequestParams;
  label: string;
  items: PropsItem[];
}

// -----------------------------------------------------------------------------

export const SelectFilter: React.FC<SelectFilterProps> = ({
  items,
  label,
  paramKey,
  params,
}) => {
  const initialValue = params[paramKey];
  const [value, setValue] = useState<string | undefined>(initialValue);
  const hasSelection = (params[paramKey] !== undefined) && (value !== '') && params[paramKey] === value;

  function handleChange (evt: React.ChangeEvent<HTMLSelectElement>) {
    const nextValue = evt.target.value;
    setValue(nextValue);
    submitSearchParams({
      [paramKey]: nextValue,
    });
  }

  function handleClickReset () {
    submitSearchParams({
      [paramKey]: undefined,
    });
  }

  const options = items.map((item) => (
    <option key={item.value + item.name} value={item.value}>{item.name}</option>
  ));

  const resetButton = hasSelection && (
    <LilX onClick={handleClickReset}/>
  );

  const classNames = [
    'SelectFilter',
    hasSelection && '_has-selection',
  ].filter(x => !!x).join(' ');

  // Using a blank string is actually the right way to reset/null an html select element.
  return (
    <div className={classNames}>
      <label>{label}:&nbsp;</label>
      <select value={value || ''} onChange={handleChange}>
        <option value={''} disabled hidden>- Select {label} -</option>
        <option value={''}></option>
        {options}
      </select>
      {resetButton}
    </div>
  );
};

// -----------------------------------------------------------------------------

export default SelectFilter;
