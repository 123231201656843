import React from 'react';

import { Button, NodeWidget, RadioGlyph } from '../components';
import { useAppDispatch, useAppSelector } from '../hooks';
import { IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { $nodeValue, setDataPathTouched, setDataPathValue } from '../store';
import { flexCenterMixin, rem } from '../styles';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  flexDirection: 'column',
  width: '100%',
  margin: 0,
  marginBottom: rem(1),
};

const textContainerStyle: React.CSSProperties = {
  marginBottom: rem(1 / 2),
};

const buttonsContainerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  justifyContent: 'space-evenly',
  width: '100%',
  marginBottom: rem(1 / 2),
};

const buttonInnerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  justifyContent: 'space-evenly',
  width: '4rem',
};

const buttonTextStyle: React.CSSProperties = {
  fontSize: 'calc(1rem * (11/12))',
  lineHeight: 'calc(1rem * (11/12))',
  paddingLeft: '0.5rem',
};

// -----------------------------------------------------------------------------

interface PolarQuestionNodeProps extends IsoformNodeProps {
  placeholder?: string;
  text?: string;
}

const PolarQuestionNodeComponent: IsoformNodeComponent<PolarQuestionNodeProps> = ({
  dataPath,
  text,
  children,
}) => {
  const nodeValue = useAppSelector($nodeValue)(dataPath);
  const appDispatch = useAppDispatch();

  function handleClickYes () {
    const val = nodeValue === true ? undefined : true;
    appDispatch(setDataPathValue(dataPath, val));
    appDispatch(setDataPathTouched(dataPath));
  }

  function handleClickNo () {
    const val = nodeValue === false ? undefined : false;
    appDispatch(setDataPathValue(dataPath, val));
    appDispatch(setDataPathTouched(dataPath));
  }

  const content = (nodeValue === true) && children;

  return (
    <NodeWidget dataPath={dataPath}>
      <div className='PolarQuestionNode' style={containerStyle}>
        <div style={textContainerStyle}>
          {text}
        </div>
        <div style={buttonsContainerStyle}>
          <Button diminished borderless onClick={handleClickYes}>
            <div style={buttonInnerStyle}>
              <RadioGlyph active={nodeValue === true} />
              <span style={buttonTextStyle}>Yes</span>
            </div>
          </Button>
          <Button diminished borderless onClick={handleClickNo}>
            <div style={buttonInnerStyle}>
              <RadioGlyph active={nodeValue === false} />
              <span style={buttonTextStyle}>No</span>
            </div>
          </Button>
        </div>
        {content}
      </div>
    </NodeWidget>
  );
};

export class PolarQuestionNode extends IsoformNode {
  static nodeName = 'PolarQuestion';
  static component = PolarQuestionNodeComponent;
}
