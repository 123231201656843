import { createAction, createReducer } from '@reduxjs/toolkit';

import {
  DataKey, DataPath, DataValue, FormDesc, formDescMap, seekFormDesc,
} from '../isoform';

// Types -----------------------------------------------------------------------

const initialState = {
  type: 'Unknown',
  key: 'initial_state',
  props: {},
  children: [],
  state: {},
} as FormDesc;

// Action Creators -------------------------------------------------------------

export const setFormDesc = createAction<FormDesc>('setFormDesc');
export const touchAllRequired = createAction('touchAllRequired');

export const setDataPathState = createAction(
  'setDataPathState',
  function prepare (dataPath: DataPath, dataKey: DataKey, dataValue: DataValue) {
    return {
      payload: {
        dataPath,
        dataKey,
        dataValue,
      },
    };
  },
);

// TODO proxy to setDataPathState
export const setDataPathValue = createAction(
  'setDataPathValue',
  function prepare (dataPath: DataPath, dataValue: DataValue) {
    return {
      payload: {
        dataPath,
        dataValue,
      },
    };
  },
);

export const setDataPathTouched = createAction<DataPath>('setDataPathTouched');
export const setDataPathFocused = createAction<DataPath>('setDataPathFocused');
export const setDataPathBlurred = createAction<DataPath>('setDataPathBlurred');

export const removeCollectionItem = createAction<DataPath>('removeCollectionItem');

export const collectionAppend = createAction<DataPath>('collectionAppend');


// Reducer ---------------------------------------------------------------------

export const formDescReducer = createReducer(initialState, (builder) => {
  builder.addCase(setFormDesc, (state, action) => {
    // TODO something deep inside @reduxjs/toolkit is causing TypeScript to flip out
    // @ts-expect-error TS2589 Type instantiation is excessively deep and possibly infinite.
    state = action.payload;
    return state;
  });

  builder.addCase(setDataPathState, (state, action) => {
    const { dataPath, dataKey, dataValue } = action.payload;
    const node = seekFormDesc(state, dataPath);
    if (node?.state) {
      node.state[dataKey] = dataValue;
    }
  });

  builder.addCase(setDataPathValue, (state, action) => {
    const { dataPath, dataValue } = action.payload;
    const node = seekFormDesc(state, dataPath);
    if (node?.state) {
      node.state.value = dataValue;
    }
  });

  builder.addCase(setDataPathTouched, (state, action) => {
    const dataPath = action.payload;
    const node = seekFormDesc(state, dataPath);
    if (node?.state) {
      node.state.touched = true;
    }
  });

  builder.addCase(setDataPathFocused, (state, action) => {
    const dataPath = action.payload;
    const node = seekFormDesc(state, dataPath);
    if (node?.state) {
      node.state.focused = true;
    }
  });

  builder.addCase(setDataPathBlurred, (state, action) => {
    const dataPath = action.payload;
    const node = seekFormDesc(state, dataPath);
    if (node?.state) {
      node.state.focused = false;
    }
  });

  builder.addCase(removeCollectionItem, (state, action) => {
    const dataPath = action.payload;
    let itemDesc = seekFormDesc(state, dataPath);
    if (itemDesc) {
      // clear self and all descendants
      itemDesc = formDescMap(itemDesc, (node) => {
        node.state.value = undefined;
        node.state.touched = false;
        return node;
      });
      // removing an item also is an act of touching it
      itemDesc.state.removed = true;
      itemDesc.state.touched = true;
    }
  });

  builder.addCase(collectionAppend, (state, action) => {
    const dataPath = action.payload;
    const collectionNode = seekFormDesc(state, dataPath);
    for (const child of collectionNode?.children || []) {
      if (child.state.removed === true) {
        delete child.state.removed;
        break;
      }
    }
  });

  builder.addCase(touchAllRequired, (state) => {
    state = formDescMap(state, (node) => {
      if (node.props.required !== true) {
        return node;
      }
      // ignore nodes hidden behind collections
      // TODO also ignore nodes with ancestors that are "removed"
      if (node.state.removed === true) {
        return node;
      }
      node.state.touched = true;
      return node;
    });
  });
});
