import React from 'react';

import { NodeCompact } from '../components';
import { useNodeHandlers } from '../hooks';
import { dataPathNodeId, FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { InputX, WithFloatingLabel } from '../nodes/support';

interface DateNodeProps extends IsoformNodeProps {
  label?: string;
}

const DateNodeComponent: IsoformNodeComponent<DateNodeProps> = ({
  dataPath,
}) => {
  const { handleChange, handleBlur } = useNodeHandlers(dataPath);
  const nodeId = dataPathNodeId(dataPath);

  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <InputX
          dataPath={dataPath}
          id={nodeId}
          name={nodeId}
          onBlur={handleBlur}
          onChange={handleChange}
          type='date'
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class DateNode extends IsoformNode {
  static nodeName = 'Date';
  static component = DateNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    label: '',
  };
}
