import React, { useContext } from 'react';

import { Slot, VisitPlanContext } from './VisitPlanContext';

// -----------------------------------------------------------------------------

interface VisitPlanActionBarProps {
  slotId: Slot['slotId'];
}

export const VisitPlanActionBar: React.FC<VisitPlanActionBarProps> = ({
  slotId,
}) => {
  const { clearSlot } = useContext(VisitPlanContext);

  function handleDelete () {
    if (window && window.confirm('Are you sure you want to clear this visit plan?')) {
      clearSlot(slotId);
    }
  }

  return (
    <div className='VisitPlanActionBar'>
      <div className='_title'>
        Visit Plan
      </div>
      <div className='_actions'>
        <a href='#delete' onClick={handleDelete}>
          <i className='bi bi-trash'></i>
          Delete
        </a>
      </div>
    </div>
  );
};

export default VisitPlanActionBar;
