import { createAction, createReducer } from '@reduxjs/toolkit';

import { FormId, FormUrl } from '../isoform';

// Types -----------------------------------------------------------------------

export interface FormContextState {
  formUrl: FormUrl | undefined;
  formId: FormId | undefined;
}

const initialState: FormContextState = {
  formUrl: undefined,
  formId: undefined,
};

// Action Creators -------------------------------------------------------------

export const setFormContextFormUrl = createAction<string | undefined>('setFormContextFormUrl');
export const setFormContextFormId = createAction<string | undefined>('setFormContextFormId');

// Reducer ---------------------------------------------------------------------

export const formContextReducer = createReducer(initialState, (builder) => {
  builder.addCase(setFormContextFormUrl, (state, action) => {
    state.formUrl = action.payload;
  });

  builder.addCase(setFormContextFormId, (state, action) => {
    state.formId = action.payload;
  });
});
