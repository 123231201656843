import React from 'react';

import { IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';

type UnknownNodeProps = IsoformNodeProps;

const UnknownNodeComponent: IsoformNodeComponent<UnknownNodeProps> = () => {
  return (<></>);
};

export class UnknownNode extends IsoformNode {
  static nodeName = 'Unknown';
  static component = UnknownNodeComponent;
}
