import React from 'react';

import { FormComponent, SpinBox } from '../components';
import { useAppSelector } from '../hooks';
import { $isLoading } from '../store';
import { globalColumnWidth, rem } from '../styles';

const containerStyle: React.CSSProperties = {
  maxWidth: rem(globalColumnWidth),
  position: 'relative',
};

export const EmbeddedForm: React.FC<Record<string, never>> = () => {
  const isLoading = useAppSelector($isLoading);

  return (
    <div className='EmbeddedForm' style={containerStyle}>
      <SpinBox gate={isLoading}>
        <FormComponent />
      </SpinBox>
    </div>
  );
};
