import React, { useContext, useLayoutEffect, useRef, useState } from 'react';

import { VisitPlanContext } from './VisitPlanContext';
import { VisitPlanSlotContent } from './VisitPlanSlotContent';

export const VisitPlanSlotSwitcher: React.FC = () => {
  const { slotSelected } = useContext(VisitPlanContext);
  const ref = useRef<HTMLDivElement>(null);
  const [lastKnownSlotId, setLastKnownSlotId] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {
    if (lastKnownSlotId === slotSelected?.slotId) {
      return;
    }

    if (!ref.current?.classList) {
      return;
    }

    const cls = ref.current.classList;

    cls.add('staged');

    setTimeout(() => {
      cls.remove('staged');
      setLastKnownSlotId(slotSelected?.slotId);
    }, 140);
  });

  const content = slotSelected && (
    <VisitPlanSlotContent slotId={slotSelected.slotId} />
  );

  return (
    <div className='VisitPlanSlotSwitcher'>
      <div ref={ref} className='content'>
        {content}
      </div>
    </div>
  );
};

export default VisitPlanSlotSwitcher;
