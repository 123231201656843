import * as logger from '../support/logger';
import { launderFormDesc } from './form-desc';
import { FormDesc, FormUrl, PersistedPayload } from './types';

interface HttpFormResponse {
  status: number;
  successful: boolean;
  formDesc: FormDesc;
}

async function formResponse (fetchResp?: Response): Promise<HttpFormResponse> {
  const data = await fetchResp?.json().catch(err => {
    logger.warn('problem parsing form response:', err);
  });

  const status = fetchResp?.status || 500;

  return {
    status,
    successful: status >= 200 && status < 300,
    formDesc: launderFormDesc(data?.form),
  };
}

export async function httpPostFormDesc (formUrl: FormUrl, formDesc: FormDesc): Promise<HttpFormResponse> {
  const payload: PersistedPayload = {
    form: formDesc,
  };

  logger.info(`http post form to ${formUrl}`, payload);

  const resp = await fetch(formUrl, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch(err => {
    logger.info('problem during form http post:', err);
  });

  return formResponse(resp || undefined);
}

export async function httpGetFormDesc (formUrl: FormUrl): Promise<HttpFormResponse> {
  const resp = await fetch(formUrl, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch(err => {
    logger.info('problem during form http get:', err);
  });

  return formResponse(resp || undefined);
}
