import { DataPath, DataValue, FormDesc, NodeId } from './types';

export function dataPathNodeId (dataPath: DataPath): NodeId {
  return dataPath.join('.');
}

// return type copied from React source
export function dataValueForInput (dataValue: DataValue): string | ReadonlyArray<string> | number {
  if (dataValue === true) {
    return 'true';
  }

  if (dataValue === false) {
    return 'false';
  }

  if (dataValue === undefined || dataValue === null) {
    return '';
  }

  if (typeof dataValue === 'string') {
    return dataValue;
  }

  if (typeof dataValue === 'number') {
    return dataValue;
  }

  return '';
}

export function n (
  type: FormDesc['type'],
  key: FormDesc['key'],
  props: FormDesc['props'] = {},
  children: FormDesc['children'] = [],
): FormDesc {
  return {
    type,
    key,
    props,
    children: children,
    state: {},
  };
}

// -----------------------------------------------------------------------------

let unknownCount = 0;

export function generateUnknownNode (): FormDesc {
  unknownCount += 1;
  return {
    type: 'Unknown',
    key: `unknown_${unknownCount}`,
    props: {},
    children: [],
    state: {},
  };
}

