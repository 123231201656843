import React from 'react';

import { NodeCompact } from '../components';
import { FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { SelectX, WithFloatingLabel } from '../nodes/support';

interface SexNodeProps extends IsoformNodeProps {
  placeholder?: string;
  label?: string;
}

const SexNodeComponent: IsoformNodeComponent<SexNodeProps> = ({
  dataPath,
}) => {
  const options = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
  ];

  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <SelectX
          dataPath={dataPath}
          options={options}
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class SexNode extends IsoformNode {
  static nodeName = 'Sex';
  static component = SexNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    label: 'Sex',
  };
}
