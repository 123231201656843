import { createContext, default as React, useEffect, useMemo, useReducer } from 'react';

import { addOrUpdate, fallback, JsonLike, submitForm } from '../../support';

// The incremental unit used for minutes
export const MINUTES_UNIT = 15;
// Be careful modifying this. It's used for display and dynamic data transformation. In fact, don't ever modify this.
export const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// Props passed to root component during init by rails-react
export interface VisitPlanRootProps {
  submitUrl: string;
  submitMethod: string;
  authenticityToken: string;
  agencyBranchId: string;
  branchPatientId: string;
  postalAddressId: string;
  serviceAuthorizationId?: string;
  patient: {
    patientId?: string;
    admittedDate: string;
  };
  serviceAuthorizations: ServiceAuthorization[];
  caregivers: Caregiver[];
  slots: {
    visitPlanScheduleId?: string;
    effectiveAt?: string;
    expiresAt?: string;
    caregiverId?: string;
    visitPlanRows: VisitPlanRow[];
  }[];
}

type ForeignValue = JsonLike; // anything that comes from the server


// Actions ---------------------------------------------------------------------

export enum Actions {
  AssignSlot = 'AssignSlot',
  ClearPlanRow = 'ClearPlanRow',
  ClearSlot = 'ClearSlot',
  SelectServiceAuthorization = 'SelectServiceAuthorization',
  SelectSlot = 'SelectSlot',
  SetPlanRowEnd = 'SetPlanRowEnd',
  SetPlanRowStart = 'SetPlanRowStart',
  SetRangeBegin = 'SetRangeBegin',
  SetRangeEnd = 'SetRangeEnd',
  SetVisitPlanScheduleId = 'SetVisitPlanScheduleId',
  Submit = 'Submit',
}

export type Action =
  | { type: Actions.AssignSlot; slotId: string; caregiverId?: string }
  | { type: Actions.ClearPlanRow; slotId: string; day: VisitPlanRow['day'] }
  | { type: Actions.ClearSlot; slotId: string }
  | { type: Actions.SelectServiceAuthorization; serviceAuthorizationId?: string }
  | { type: Actions.SelectSlot; slotId: string }
  | { type: Actions.SetPlanRowEnd; slotId: string; day: VisitPlanRow['day']; hour?: number; minute?: number }
  | { type: Actions.SetPlanRowStart; slotId: string; day: VisitPlanRow['day']; hour?: number; minute?: number }
  | { type: Actions.SetRangeBegin; slotId: string; date?: string }
  | { type: Actions.SetRangeEnd; slotId: string; date?: string }
  | { type: Actions.SetVisitPlanScheduleId; slotId: string; value?: string }
  | { type: Actions.Submit };


// State -----------------------------------------------------------------------

interface RowParams {
  startHour?: number; // 0-23
  startMinute?: number; // 0-59
  endHour?: number; // 0-23
  endMinute?: number; // 0-59
}

export interface VisitPlanRow extends RowParams {
  [key: string]: number | undefined;
  day: number; // 0-6, Sun-Sat
}

export interface Slot {
  slotId: string;
  name: string;
  visitPlanScheduleId?: string;
  caregiverId?: string;
  rangeBegin?: string;
  rangeEnd?: string;
  shortName: string;
  visitPlanRows: VisitPlanRow[];
  destroy?: boolean; // mark for destroy on the server
}

export interface Caregiver {
  branchCaregiverId: string;
  caregiverId: string;
  initials: string;
  name: string;
}

export interface ServiceAuthorization {
  serviceAuthorizationId: string;
  name: string;
  authEndsAt: string;
  authStartsAt: string;
  hours: string;
}

export interface State {
  rootProps?: VisitPlanRootProps;
  selectedSeviceAuthorizationId?: string;
  selectedSlotId?: string;
  slots: Slot[];
}

export const initialState: State = {
  selectedSlotId: 'slot-a',
  slots: [],
};


// Support ---------------------------------------------------------------------

function isSlotDataEmpty (slot?: Slot): boolean {
  if (slot?.visitPlanScheduleId !== undefined) {
    return false;
  }

  if (slot === undefined) {
    return true;
  }
  return slot.caregiverId === undefined
    && slot.rangeBegin === undefined
    && slot.rangeEnd === undefined
    && slot.visitPlanRows.length === 0;
}

function genEmptySlot (): Slot {
  return {
    slotId: 'slot-x',
    name: 'Caregiver X',
    shortName: 'X',
    caregiverId: undefined,
    visitPlanRows: [],
  };
}

function genSlotNameProps (index: number) {
  const letter = (index + 10).toString(36); // use ascii char indexing
  return {
    slotId: `slot-${letter}`,
    name: `Caregiver ${letter.toUpperCase()}`,
    shortName: letter.toUpperCase(),
  };
}

export function rebalanceSlots (state: State): State {
  const slotsNext = (state.slots || [])
    .filter(x => !isSlotDataEmpty(x))
    .concat([genEmptySlot()]);

  if ((slotsNext.length) <= 1) {
    slotsNext.push(genEmptySlot());
  }

  const slots = slotsNext.map((x, i) => ({
    ...x,
    ...genSlotNameProps(i),
  }));

  return {
    ...state,
    slots,
  };
}

export function rowHourSum (row: VisitPlanRow | undefined): number | undefined {
  if (row === undefined
    || row.startHour === undefined
    || row.startMinute === undefined
    || row.endHour === undefined
    || row.endMinute === undefined
  ) {
    return undefined;
  }

  const endHourSum = row.endHour + (row.endMinute / 60);
  const startHourSum = row.startHour + (row.startMinute / 60);

  if (startHourSum < endHourSum) {
    return endHourSum - startHourSum;
  }

  return (endHourSum + 24) - startHourSum;
}

// value forced to be a number (if not undefuned) and rounded to nearest unit increment between min and max
function boundedValue (value: ForeignValue, unit: number, min: number, max: number): number | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }

  const valueCoerced: number = parseInt((value || 0).toString(), 10) || 0;

  return Math.min(max,
    Math.max(min,
      Math.round(valueCoerced / unit) * unit,
    ),
  );
}

// Apply a new set of params to an existing row, making sure all values are correct.
export function reconcileRow (prev: VisitPlanRow, next: RowParams): VisitPlanRow {
  // extra hour to add to rows with no end set yet
  const autoPad = (n?: number) => n === undefined ? n : n + 1;
  const boundedHour = (val: ForeignValue) => boundedValue(val, 1, 0, 23);
  const boundedMinute = (val: ForeignValue) => boundedValue(val, MINUTES_UNIT, 0, 59);

  // simply merge the params with the previous row, ensuring values are valid
  const rowMerged = {
    ...prev,
    startHour: boundedHour(fallback(next.startHour, prev.startHour)),
    startMinute: boundedMinute(fallback(next.startMinute, prev.startMinute)),
    endHour: boundedHour(fallback(next.endHour, prev.endHour)),
    endMinute: boundedMinute(fallback(next.endMinute, prev.endMinute)),
  };

  // setting the hour always sets the minute to zero if it's not already set.
  const rowWithMinutes = {
    ...rowMerged,
    ...(rowMerged.startHour !== undefined || rowMerged.endHour !== undefined) && {
      startMinute: fallback(rowMerged.startMinute, 0),
      endMinute: fallback(rowMerged.endMinute, 0),
    },
  };

  const rowNext = {
    ...rowWithMinutes,
    endHour: fallback(rowWithMinutes.endHour, autoPad(rowWithMinutes.startHour)),
  };

  return rowNext;
}


// Reducer ---------------------------------------------------------------------

function updateSlot (state: State, slotId: Slot['slotId'], cb: (slot: Slot) => Slot): State {
  return {
    ...state,
    slots: addOrUpdate<Slot>(state.slots, 'slotId', slotId, (slot) => (
      cb({
        ...slot,
        destroy: false, // updating any existing slot unmarks it for destroy by default
      })
    )),
  };
}

function updateVisitPlanRow (
  state: State,
  slotId: Slot['slotId'],
  day: VisitPlanRow['day'],
  cb: (row: VisitPlanRow) => VisitPlanRow,
): State {
  return updateSlot(state, slotId, (slot) => {
    return {
      ...slot,
      visitPlanRows: addOrUpdate<VisitPlanRow>(slot.visitPlanRows, 'day', day, cb),
    };
  });
}

// ----

function collectSubmitData (state: State): JsonLike {
  const plans = state.slots
    .filter((slot) => !isSlotDataEmpty(slot))
    .map((slot) => {
      const rowsByDay = slot.visitPlanRows.reduce((accu, row) => {
        return {
          ...accu,
          [row.day]: row,
        };
      }, {} as Record<VisitPlanRow['day'], VisitPlanRow>);

      // Use null instead of undefined so that the payload will include nulls and the server will do the thing.
      const dayGet = (day: number, prop: string) => (rowsByDay[day] !== undefined
        ? fallback(rowsByDay[day][prop], null)
        : null);

      const explodedRows = WEEKDAYS.reduce((accu, weekday, idx) => {
        const prefix = weekday.toLowerCase();
        return {
          ...accu,
          [`${prefix}_hour_starts`]: dayGet(idx, 'startHour'),
          [`${prefix}_minute_starts`]: dayGet(idx, 'startMinute'),
          [`${prefix}_hour_ends`]: dayGet(idx, 'endHour'),
          [`${prefix}_minute_ends`]: dayGet(idx, 'endMinute'),
        };
      }, {} as Record<string, number | null>);

      const caregiver = (state.rootProps?.caregivers || []).find(c => c.caregiverId === slot.caregiverId);
      const branchCaregiverId = caregiver !== undefined ? caregiver.branchCaregiverId : undefined;

      // visit_plan_schedule item
      return {
        'id': slot.visitPlanScheduleId,
        '_destroy': slot.destroy ? true : undefined, // this tells Rails to remove the record entirely
        'branch_caregiver_id': branchCaregiverId,
        'effective_at': slot.rangeBegin,
        'expires_at': slot.rangeEnd,
        ...explodedRows,
      };
    });

  const plansCleaned = plans.map(x => JSON.parse(JSON.stringify(x)))
    .filter(x => Object.keys(x).length > 0);

  // The data structure expected by Rails
  return {
    'visit_plan': {
      'tmhp_visit_plan_attributes': {
        'service_authorization_id': state.selectedSeviceAuthorizationId,
      },
      'branch_patient_id': state.rootProps?.branchPatientId,
      'postal_address_id': state.rootProps?.postalAddressId,
      'visit_plan_schedules_attributes': plansCleaned,
    },
  };
}

// ----

export function reducer (state: State, action: Action): State {
  // rebalance slots on every single action. (TODO maybe optimize some day)
  return rebalanceSlots(
    reduceInner(state, action),
  );
}

export function reduceInner (state: State, action: Action): State {
  switch (action.type) {
  case Actions.SelectServiceAuthorization:
    return {
      ...state,
      selectedSeviceAuthorizationId: action.serviceAuthorizationId,
    };
  case Actions.SelectSlot:
    return {
      ...state,
      selectedSlotId: action.slotId,
    };
  case Actions.AssignSlot:
    return updateSlot(state, action.slotId, (slot) => ({
      ...slot,
      caregiverId: action.caregiverId,
    }));
  case Actions.ClearSlot:
    return updateSlot(state, action.slotId, (slot) => ({
      ...slot,
      caregiverId: undefined,
      destroy: true,
      rangeBegin: undefined,
      rangeEnd: undefined,
      visitPlanRows: [],
    }));
  case Actions.SetVisitPlanScheduleId:
    return updateSlot(state, action.slotId, (slot) => ({
      ...slot,
      visitPlanScheduleId: action.value,
    }));
  case Actions.SetRangeBegin:
    return updateSlot(state, action.slotId, (slot) => ({
      ...slot,
      rangeBegin: action.date,
    }));
  case Actions.SetRangeEnd:
    return updateSlot(state, action.slotId, (slot) => ({
      ...slot,
      rangeEnd: action.date,
    }));
  case Actions.SetPlanRowStart:
    return updateVisitPlanRow(state, action.slotId, action.day, (rowPrev) => {
      return reconcileRow(rowPrev, { startHour: action.hour, startMinute: action.minute });
    });
  case Actions.SetPlanRowEnd:
    return updateVisitPlanRow(state, action.slotId, action.day, (rowPrev) => {
      return reconcileRow(rowPrev, { endHour: action.hour, endMinute: action.minute });
    });
  case Actions.ClearPlanRow:
    return updateSlot(state, action.slotId, (slot) => {
      return {
        ...slot,
        visitPlanRows: slot.visitPlanRows.filter((row) => row.day !== action.day),
      };
    });
  case Actions.Submit: {
    const submitData = collectSubmitData(state);
    if (state.rootProps === undefined) {
      return state;
    }
    submitForm(
      state.rootProps?.submitMethod,
      state.rootProps?.submitUrl,
      submitData,
      state.rootProps?.authenticityToken,
    );
    return state;
  }
  default:
    return state;
  }
}

const reducerDebug: (typeof reducer) = (state, action) => {
  console.log(`VisitPlan ${action.type}:`, action);
  const next = reducer(state, action);
  console.log('VisitPlan State (capture):', JSON.parse(JSON.stringify(next)));
  return next;
};


// Context ---------------------------------------------------------------------

interface VisitPlanContextInterface {
  // ---- views ----
  caregivers: Caregiver[];
  caregiversAssigned: Caregiver[];
  hoursAuthorized: number;
  hoursTotal: number;
  isFormValid: boolean;
  rootProps: VisitPlanRootProps;
  serviceAuthorizationSelected?: ServiceAuthorization;
  serviceAuthorizations: ServiceAuthorization[];
  slotSelected: Slot | undefined;
  slots: State['slots'];
  utilizationRatio: number;

  // ---- queries ----
  isSlotActive: (slotId: Slot['slotId']) => boolean;
  isSlotEmpty: (slotId: Slot['slotId']) => boolean;
  slot: (slotId: Slot['slotId']) => Slot | undefined;
  slotCaregiver: (slotId: Slot['slotId']) => Caregiver | undefined;

  // ---- actions ----
  assignSlot: (slotId: string, caregiverId: string | undefined) => void;
  clearSlot: (slotId: string) => void;
  dispatch: React.Dispatch<Action>;
  selectServiceAuthorization: (serviceAuthorizationId: string | undefined) => void;
  selectSlot: (slotId: string) => void;
}

export const VisitPlanContext = createContext<VisitPlanContextInterface>({} as VisitPlanContextInterface);


// Provider --------------------------------------------------------------------

// Remove nulls from server data, in favor of using undefined values locally.
function cleanRootProps (obj: VisitPlanRootProps): VisitPlanRootProps {
  return JSON.parse(JSON.stringify(obj), (_, value) => {
    if (value === null) {
      return undefined;
    }
    return value;
  });
}

interface VisitPlanContextProviderProps {
  children?: React.ReactNode;
  rootProps: VisitPlanRootProps;
}

export const VisitPlanContextProvider: React.FC<VisitPlanContextProviderProps> = ({
  children,
  rootProps,
}) => {
  const rootPropsX = cleanRootProps(rootProps);

  const initialStateX = {
    ...initialState,
    // create n blank slots so the root props slot actions can target them
    slots: rootPropsX.slots.map((_, i) => {
      return {
        ...genEmptySlot(),
        ...genSlotNameProps(i),
      };
    }),
    rootProps: rootPropsX,
  };

  const [state, dispatch] = useReducer(reducerDebug, initialStateX);

  // when this component is initialized, it needs to set the local state
  // to match the server state as specified by the root props.
  // By using action dispatches to convert server state to local state,
  // we avoid getting into a bad state.
  useEffect(() => {
    // dispatch actions for slots
    state.slots
      .filter((_, i) => rootProps.slots[i] !== undefined)
      .forEach((slot, i) => {
        const rootSlot = rootProps.slots[i];

        // this has to be done first so the slot is not seen as empty during rebalance
        dispatch({
          type: Actions.SetVisitPlanScheduleId,
          slotId: slot.slotId,
          value: rootSlot.visitPlanScheduleId,
        });

        dispatch({
          type: Actions.SetRangeBegin,
          slotId: slot.slotId,
          date: rootSlot.effectiveAt,
        });

        dispatch({
          type: Actions.SetRangeEnd,
          slotId: slot.slotId,
          date: rootSlot.expiresAt,
        });

        dispatch({
          type: Actions.AssignSlot,
          slotId: slot.slotId,
          caregiverId: rootSlot.caregiverId,
        });

        rootSlot.visitPlanRows.forEach((row) => {
          dispatch({
            type: Actions.SetPlanRowStart,
            slotId: slot.slotId,
            day: row.day,
            hour: row.startHour,
            minute: row.startMinute,
          });
          dispatch({
            type: Actions.SetPlanRowEnd,
            slotId: slot.slotId,
            day: row.day,
            hour: row.endHour,
            minute: row.endMinute,
          });
        });
      });

    // dispatch action for associated service auth during edit (if any)
    dispatch({
      type: Actions.SelectServiceAuthorization,
      serviceAuthorizationId: rootProps.serviceAuthorizationId,
    });
  }, [/* run only once */]); // eslint-disable-line react-hooks/exhaustive-deps

  const slots = useMemo<Slot[]>(() => {
    return state.slots || [];
  }, [state.slots]);

  const slot: VisitPlanContextInterface['slot'] = (slotId) => {
    return slots.find(s => s.slotId === slotId);
  };

  const caregivers = useMemo<Caregiver[]>(() => {
    return state.rootProps?.caregivers || [];
  }, [state.rootProps]);

  const caregiversAssigned = useMemo<Caregiver[]>(() => {
    const assignedIds = slots.reduce((accu, slot) => {
      return slot.caregiverId === undefined
        ? accu : { ...accu, [slot.caregiverId]: true };
    }, {} as Record<Caregiver['caregiverId'], boolean>);

    return caregivers.filter(c => assignedIds[c.caregiverId]);
  }, [slots, caregivers]);

  const serviceAuthorizations = state.rootProps?.serviceAuthorizations || [];

  const slotSelected = useMemo<Slot | undefined>(() => {
    return state.slots.find(s => s.slotId === state.selectedSlotId);
  }, [state.slots, state.selectedSlotId]);

  function selectSlot (slotId: string) {
    dispatch({
      type: Actions.SelectSlot,
      slotId,
    });
  }

  function clearSlot (slotId: string) {
    dispatch({
      type: Actions.ClearSlot,
      slotId,
    });
  }

  function selectServiceAuthorization (serviceAuthorizationId: string | undefined) {
    dispatch({
      type: Actions.SelectServiceAuthorization,
      serviceAuthorizationId,
    });
  }

  const serviceAuthorizationSelected = useMemo<ServiceAuthorization | undefined>(() => {
    return (state.rootProps?.serviceAuthorizations || []).find(sa => sa.serviceAuthorizationId === state.selectedSeviceAuthorizationId);
  }, [state.selectedSeviceAuthorizationId, state.rootProps?.serviceAuthorizations]);

  function assignSlot (slotId: string, caregiverId: string) {
    dispatch({
      type: Actions.AssignSlot,
      slotId,
      caregiverId,
    });
  }

  function isSlotActive (slotId: string): boolean {
    return state.selectedSlotId === slotId;
  }

  function isSlotEmpty (slotId: string): boolean {
    return isSlotDataEmpty(
      state.slots.find(s => s.slotId === slotId),
    );
  }

  const slotCaregiver: VisitPlanContextInterface['slotCaregiver'] = (slotId) => {
    const slot = slots.find(s => s.slotId === slotId);
    if (slot === undefined) {
      return undefined;
    }
    return caregivers.find(c => c.caregiverId === slot.caregiverId);
  };

  const isFormValid = serviceAuthorizationSelected !== undefined;

  const hoursAuthorized = Number(serviceAuthorizationSelected?.hours) || 0;

  const hoursTotal = useMemo<number>(() => {
    return slots.reduce((total, slot) => {
      return total + slot.visitPlanRows.reduce((sub, row) => {
        return sub + (rowHourSum(row) || 0);
      }, 0);
    }, 0);
  }, [slots]);

  const utilizationRatio = useMemo<number>(() => {
    return isFinite(hoursTotal / hoursAuthorized)
      ? hoursTotal / hoursAuthorized
      : 0;
  }, [serviceAuthorizationSelected, hoursTotal]);

  const context = {
    assignSlot,
    caregivers,
    caregiversAssigned,
    clearSlot,
    dispatch,
    hoursAuthorized,
    hoursTotal,
    isFormValid,
    isSlotActive,
    isSlotEmpty,
    rootProps: state.rootProps,
    selectServiceAuthorization,
    selectSlot,
    serviceAuthorizationSelected,
    serviceAuthorizations,
    slot,
    slotCaregiver,
    slotSelected,
    slots,
    utilizationRatio,
  } as VisitPlanContextInterface;

  return (
    <VisitPlanContext.Provider value={context}>
      {children}
    </VisitPlanContext.Provider>
  );
};
