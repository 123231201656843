export function hoursDisplay (hours?: number): string {
  if (
    hours === undefined
      || hours === null
      || Number.isNaN(hours)
      || hours.toFixed === undefined
  ) {
    return '0';
  }

  // check for a value without a natural decimal
  if ((hours % 1) === 0) {
    return hours.toString();
  }

  return hours.toFixed(2).replace(/0+$/, '');
}
