import React from 'react';

import { NodeCompact } from '../components';
import { IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { TextareaX, WithFloatingLabel } from '../nodes/support';

// -----------------------------------------------------------------------------

interface FreeFormTextNodeProps extends IsoformNodeProps {
  placeholder?: string;
}

const FreeFormTextNodeComponent: IsoformNodeComponent<FreeFormTextNodeProps> = ({
  dataPath,
}) => {
  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <TextareaX
          dataPath={dataPath}
          type='text'
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class FreeFormTextNode extends IsoformNode {
  static nodeName = 'FreeFormText';
  static component = FreeFormTextNodeComponent;
}
