import React from 'react';

import { NodeProblems, NodeRoot } from '../components';
import { IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';

// -----------------------------------------------------------------------------

interface ContainerNodeProps extends IsoformNodeProps {
  title?: string;
}

// -----------------------------------------------------------------------------

const ContainerNodeComponent: IsoformNodeComponent<ContainerNodeProps> = ({
  nodeId,
  dataPath,
  children,
}) => {
  return (
    <NodeRoot key={nodeId}>
      <NodeProblems dataPath={dataPath} />
      {children}
    </NodeRoot>
  );
};

export class ContainerNode extends IsoformNode {
  static nodeName = 'Container';
  static component = ContainerNodeComponent;
}
