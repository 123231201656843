import React from 'react';
import { default as ReactMarkdown } from 'react-markdown';

import { NodeWidget } from '../components';
import { IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';

// -----------------------------------------------------------------------------

const docContainerStyle: React.CSSProperties = {
  overflow: 'auto',
  maxHeight: '24rem',
};

// -----------------------------------------------------------------------------

interface LegalAgreementNodeProps extends IsoformNodeProps {
  markdown?: string;
}

const LegalAgreementNodeComponent: IsoformNodeComponent<LegalAgreementNodeProps> = ({
  markdown,
  dataPath,
}) => {
  return (
    <NodeWidget dataPath={dataPath}>
      <div style={docContainerStyle}>
        <ReactMarkdown>{markdown || ''}</ReactMarkdown>
      </div>
    </NodeWidget>
  );
};

export class LegalAgreementNode extends IsoformNode {
  static nodeName = 'LegalAgreement';
  static component = LegalAgreementNodeComponent;
}
