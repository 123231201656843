import React, { useContext } from 'react';

import { hoursDisplay } from '../../support';
import { VisitPlanContext } from './VisitPlanContext';

export const VisitPlanUtilizationPercent: React.FC = () => {
  const {
    utilizationRatio,
  } = useContext(VisitPlanContext);

  const percent = utilizationRatio * 100;

  const className = [
    'VisitPlanUtilizationPercent',
    (utilizationRatio > 1) && '_over-budget',
  ].filter(x => !!x).join(' ');

  return (
    <span className={className}>{hoursDisplay(percent)}%</span>
  );
};

export default VisitPlanUtilizationPercent;
