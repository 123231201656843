import React from 'react';

import { VisitPlanContextProvider, VisitPlanRootProps } from './VisitPlanContext';
import { VisitPlanServiceAuthorization } from './VisitPlanServiceAuthorization';
import { VisitPlanSlotNickels } from './VisitPlanSlotNickels';
import { VisitPlanSlotSwitcher } from './VisitPlanSlotSwitcher';
import { VisitPlanSubmit } from './VisitPlanSubmit';
import { VisitPlanTitle } from './VisitPlanTitle';

// -----------------------------------------------------------------------------

export const VisitPlan: React.FC<VisitPlanRootProps> = (rootProps) => {
  return (
    <div className='VisitPlan'>
      <VisitPlanContextProvider rootProps={rootProps}>
        <VisitPlanServiceAuthorization />
        <VisitPlanTitle />
        <VisitPlanSlotNickels />
        <VisitPlanSlotSwitcher />
        <VisitPlanSubmit />
      </VisitPlanContextProvider>
    </div>
  );
};

export default VisitPlan;
