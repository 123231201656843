import React from 'react';

import { globalInputHeight, Palette, rem } from '../styles';

// -----------------------------------------------------------------------------

const closeButtonStyle: React.CSSProperties = {
  height: rem(globalInputHeight),
  width: 'auto',
  border: 'none',
  boxSizing: 'border-box',
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  zIndex: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

// -----------------------------------------------------------------------------

interface CloseButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const CloseButton: React.FC<CloseButtonProps> = ({
  onClick,
}) => {
  const a = (4 / 12);
  const b = 1 - a;

  return (
    <button style={closeButtonStyle} onClick={onClick}>
      <svg viewBox='0 0 1 1' width='1.25rem' height='1.25rem'>
        <circle cx='0.5' cy='0.5' r='0.5' fill={Palette.ActionableDim} />
        <line
          x1={a} y1={a} x2={b} y2={b}
          vectorEffect='non-scaling-stroke'
          strokeWidth='2'
          stroke='white'
          strokeLinecap='round'
        />
        <line
          x1={b} y1={a} x2={a} y2={b}
          vectorEffect='non-scaling-stroke'
          strokeWidth='2'
          stroke='white'
          strokeLinecap='round'
        />
      </svg>
    </button>
  );
};
