import React, { useContext } from 'react';

import { ResponsiveSection } from '../shared';
import { Slot, VisitPlanContext } from './VisitPlanContext';

interface VisitPlanCaregiverSelectProps {
  slotId: Slot['slotId'];
}

export const VisitPlanCaregiverSelect: React.FC<VisitPlanCaregiverSelectProps> = ({
  slotId,
}) => {
  const {
    assignSlot,
    caregivers,
    slot: _slot,
    slotCaregiver,
  } = useContext(VisitPlanContext);
  const slot = _slot(slotId);
  const caregiver = slotCaregiver(slotId);
  const value = caregiver?.caregiverId;

  function handleChange (evt: React.ChangeEvent<HTMLSelectElement>) {
    assignSlot(
      slotId,
      evt.currentTarget.value === '' ? undefined : evt.currentTarget.value,
    );
  }

  const options = caregivers.map((c) => (
    <option key={c.caregiverId} value={c.caregiverId}>{c.name}</option>
  ));

  const label = slot?.name
    ? `${slot.name}`
    : '...';

  return (
    <div className='VisitPlanCaregiverSelect'>
      <ResponsiveSection title={label}>
        <div className='_select_content'>
          <select value={value || ''} onChange={handleChange}>
            <option value={''} disabled hidden>- Unassigned -</option>
            <option value={''}></option>
            {options}
          </select>
        </div>
      </ResponsiveSection>
    </div>
  );
};

export default VisitPlanCaregiverSelect;

