import React, { useContext } from 'react';

import { ResponsiveSection } from '../shared/ResponsiveSection';
import { Actions, Slot, VisitPlanContext } from './VisitPlanContext';

interface VisitPlanRangeSelectProps {
  slotId: Slot['slotId'];
}

export const VisitPlanRangeSelect: React.FC<VisitPlanRangeSelectProps> = ({
  slotId,
}) => {
  const {
    dispatch,
    slot: _slot,
  } = useContext(VisitPlanContext);

  const slot = _slot(slotId);

  function handleChangeBegins (evt: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: Actions.SetRangeBegin,
      slotId,
      date: evt.target.value,
    });
  }

  function handleChangeEnds (evt: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: Actions.SetRangeEnd,
      slotId,
      date: evt.target.value,
    });
  }

  return (
    <div className='VisitPlanRangeSelect'>
      <ResponsiveSection title='Service Scheduling'>
        <div className='_range_content'>
          <div>
            <label>Assignment Begins</label>
            <input
              type='date'
              value={slot?.rangeBegin || ''}
              max={slot?.rangeEnd}
              onChange={handleChangeBegins}
            />
          </div>
          <div>
            <label>Assignment Ends</label>
            <input
              type='date'
              value={slot?.rangeEnd || ''}
              min={slot?.rangeBegin}
              onChange={handleChangeEnds}
            />
          </div>
        </div>
      </ResponsiveSection>
    </div>
  );
};

export default VisitPlanRangeSelect;
