import React from 'react';

import { NodeProblems, NodeRoot } from '../components';
import { DataPath, dataPathNodeId } from '../isoform';
import { LabelX } from '../nodes/support';

// -----------------------------------------------------------------------------

const containerStyle: React.CSSProperties = {
  padding: 0,
  paddingBottom: 'calc(1rem * (8/12))',
  display: 'grid',
  gridTemplateColumns: '2fr 3fr',
  gridTemplateRows: 'auto',
};

const labelContainerStyle: React.CSSProperties = {
  gridRow: '1 / span 2',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  paddingTop: 'calc(1rem * (8/12))',
};

// -----------------------------------------------------------------------------

interface NodeStandardProps {
  dataPath: DataPath;
  children: React.ReactNode;
}

export const NodeStandard: React.FC<NodeStandardProps> = ({
  dataPath,
  children,
}) => {
  const nodeId = dataPathNodeId(dataPath);
  return (
    <NodeRoot key={nodeId}>
      <div className='NodeStandard' style={containerStyle}>
        <div style={labelContainerStyle}>
          <LabelX dataPath={dataPath} />
        </div>
        <div className='input'>
          {children}
        </div>
        <NodeProblems dataPath={dataPath} />
      </div>
    </NodeRoot >
  );
};
