import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { RequestId, RequestsState } from './requests-slice';

// Selectors -------------------------------------------------------------------

const $requests = (state: RootState): RequestsState => state.requests;

interface RequestStatus {
  isPending: boolean;
  isFulfilled: boolean;
  isRejected: boolean;
}

export const $requestStatus = createSelector($requests, (requests) => {
  return function requestStatus (requestId: RequestId): RequestStatus {
    const status = requests[requestId];
    return {
      isPending: status === 'pending',
      isFulfilled: status === 'fulfilled',
      isRejected: status === 'rejected',
    };
  };
});

export const $isLoading = createSelector($requestStatus, (requestStatus) => {
  return requestStatus('fetchForm').isPending || requestStatus('submitForm').isPending;
});
