/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';

import { useAppSelector, useNodeHandlers } from '../../hooks';
import { DataPath, dataPathNodeId, dataValueForInput } from '../../isoform';
import { $nodeProps, $nodeState, $nodeValidation } from '../../store';
import { focusedBorderMixin, inputBaseMixin, Palette } from '../../styles';
import { isPresent } from '../../support';

// -----------------------------------------------------------------------------

const inputStyle: React.CSSProperties = {
  ...inputBaseMixin,
};

// -----------------------------------------------------------------------------

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface InputXProps extends InputProps {
  dataPath: DataPath;
  labelOverride?: string;
  style?: React.CSSProperties;
}

export const InputX: React.FC<InputXProps> = (props) => {
  const nodeId = dataPathNodeId(props.dataPath);

  const { handleChange, handleBlur, handleFocus } = useNodeHandlers(props.dataPath);
  const { hasProblems } = useAppSelector($nodeValidation)(props.dataPath);
  const { required, label, readonly } = useAppSelector($nodeProps)(props.dataPath);
  const { value, focused } = useAppSelector($nodeState)(props.dataPath);

  const ref = React.createRef<HTMLInputElement>();

  const inputStyleX = {
    ...inputStyle,
    ...props.style,
    ...hasProblems && {
      borderColor: Palette.Attention,
    },
    ...focused && focusedBorderMixin,
  };

  let typeX = props.type;

  // only switch into date picker mode when focused
  if (props.type === 'date' && (!focused && !isPresent(value))) {
    typeX = 'text';
  }

  const labelX = isPresent(props.labelOverride) ? props.labelOverride : label;

  const propsX = {
    ...props,
    placeholder: (focused || isPresent(value)) ? undefined : labelX,
    type: typeX,
  } as InputProps;

  // @ts-ignore
  delete propsX.dataPath;
  // @ts-ignore
  delete propsX.labelOverride;

  return (
    <input
      id={nodeId}
      name={nodeId}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      ref={ref}
      required={!!required}
      style={inputStyleX}
      type='text'
      value={dataValueForInput(value)}
      readOnly={!!readonly}
      {...propsX}
    />
  );
};
