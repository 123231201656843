import React from 'react';

import { NodeCompact } from '../components';
import { FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { InputX, WithFloatingLabel } from '../nodes/support';
import { emailValidator } from '../validators';

interface EmailNodeProps extends IsoformNodeProps {
  placeholder?: string;
  label?: string;
}

const EmailNodeComponent: IsoformNodeComponent<EmailNodeProps> = ({
  placeholder,
  dataPath,
}) => {
  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <InputX
          dataPath={dataPath}
          type='email'
          autoComplete='email'
          placeholder={placeholder}
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class EmailNode extends IsoformNode {
  static nodeName = 'Email';
  static component = EmailNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    placeholder: 'name@example.com',
    label: 'Email',
  };

  static validators = [
    emailValidator(),
  ];
}
