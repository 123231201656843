import React, { useContext } from 'react';

import { Nickel } from '../shared';
import { Slot, VisitPlanContext } from './VisitPlanContext';

// -----------------------------------------------------------------------------

interface VisitPlanSlotNickelProps {
  slotId: Slot['slotId'];
}

export const VisitPlanSlotNickel: React.FC<VisitPlanSlotNickelProps> = ({
  slotId,
}) => {
  const {
    selectSlot,
    isSlotActive,
    slot: _slot,
    slotCaregiver,
  } = useContext(VisitPlanContext);

  const slot = _slot(slotId);
  const active = isSlotActive(slotId);
  const caregiver = slotCaregiver(slotId);

  const underlineEl = active && (
    <div className='underline'></div>
  );

  const slotName = (
    (caregiver && caregiver.initials)
    || (active && slot?.shortName)
  ) || undefined;

  function handleClick () {
    selectSlot(slotId);
  }

  const solid = caregiver !== undefined;

  return (
    <div className='VisitPlanSlotNickel' onClick={handleClick}>
      <Nickel label={slotName} solid={solid} />
      {underlineEl}
    </div>
  );
};

// -----------------------------------------------------------------------------

export const VisitPlanSlotNickels: React.FC = () => {
  const { slots } = useContext(VisitPlanContext);

  const nickelEls = (slots).map((slot) => (
    <VisitPlanSlotNickel
      key={slot.slotId}
      slotId={slot.slotId}
    />
  ));

  return (
    <div className='VisitPlanSlotNickels'>
      {nickelEls}
    </div>
  );
};

export default VisitPlanSlotNickels;
