import React from 'react';

import { Palette } from '../styles';

// -----------------------------------------------------------------------------

interface RadioGlyphProps {
  active: boolean;
}

export const RadioGlyph: React.FC<RadioGlyphProps> = ({
  active,
}) => {
  const rw = 1;

  const innerCircle = active && (
    <circle cx='0.5' cy='0.5' r={(12 - (rw * (Math.PI * 2))) / 12 / 2} fill={Palette.Actionable} />
  );

  return (
    <svg viewBox='0 0 1 1' width='1rem' height='1rem'>
      <circle cx='0.5' cy='0.5' r={(12 - rw) / 12 / 2} stroke={Palette.Actionable} strokeWidth={rw / 12} fill='none' />
      {innerCircle}
    </svg>
  );
};
