import React from 'react';

import { NodeCompact } from '../components';
import { FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { SelectX, WithFloatingLabel } from '../nodes/support';

type OptionItem = { value: string; name: string };

interface PhoneKindNodeProps extends IsoformNodeProps {
  placeholder?: string;
  label?: string;
  options?: OptionItem[];
}

const PhoneKindNodeComponent: IsoformNodeComponent<PhoneKindNodeProps> = ({
  options,
  dataPath,
}) => {
  return (
    <NodeCompact dataPath={dataPath}>
      <WithFloatingLabel dataPath={dataPath}>
        <SelectX
          dataPath={dataPath}
          options={options || []}
          autoComplete='address-level1'
        />
      </WithFloatingLabel>
    </NodeCompact>
  );
};

export class PhoneKindNode extends IsoformNode {
  static nodeName = 'PhoneKind';
  static component = PhoneKindNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    label: 'Kind',
    options: [
      { name: 'Mobile', value: 'mobile' },
      { name: 'Landline', value: 'landline' },
      { name: 'Fax', value: 'fax' },
    ],
  };
}
