import React from 'react';

import { NodeStandard } from '../components';
import { useNodeHandlers } from '../hooks';
import { dataPathNodeId, FormDescProps, IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';

interface DobNodeProps extends IsoformNodeProps {
  label?: string;
}

const DobNodeComponent: IsoformNodeComponent<DobNodeProps> = ({
  dataPath,
}) => {
  const { handleChange, handleBlur } = useNodeHandlers(dataPath);
  const nodeId = dataPathNodeId(dataPath);

  const todayIsoDate = (new Date()).toISOString().substring(0, 10);

  return (
    <NodeStandard dataPath={dataPath}>
      <input
        autoComplete='bday'
        id={nodeId}
        max={todayIsoDate}
        name={nodeId}
        onBlur={handleBlur}
        onChange={handleChange}
        type='date'
      />
    </NodeStandard>
  );
};

export class DobNode extends IsoformNode {
  static nodeName = 'Dob';
  static component = DobNodeComponent;
  static defaultProps: FormDescProps = {
    ...IsoformNode.defaultProps,
    label: '',
  };
}
