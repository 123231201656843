import React from 'react';

import { globalButtonVerticalPadding, Palette, rem } from '../styles';

interface ButtonProps {
  augmented?: boolean;
  borderless?: boolean;
  children?: React.ReactNode;
  diminished?: boolean;
  disabled?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text?: string;
  width?: number | string;
}

const buttonStyle: React.CSSProperties = {
  backgroundColor: Palette.Actionable,
  border: '1px solid',
  borderRadius: '3px',
  boxSizing: 'border-box',
  color: Palette.White,
  cursor: 'pointer',
  fontSize: 'calc(1rem * (10/12))',
  fontWeight: 'bold',
  height: 'calc(2rem)',
  lineHeight: 'calc(1rem * (10/12))',
  margin: 0,
  padding: `calc(1rem * (3/12)) ${rem(globalButtonVerticalPadding)}`,
  textDecoration: 'none',
  textTransform: 'uppercase',
  userSelect: 'none',
};

const diminishedStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  color: Palette.Actionable,
};

const augmentedStyle: React.CSSProperties = {
  fontSize: 'calc(1rem * (12/12))',
  height: 'calc(2.5rem)',
  lineHeight: 'calc(1rem * (12/12))',
  width: '100%',
};

const disabledStyle: React.CSSProperties = {
  backgroundColor: Palette.Neutral,
};

export const Button: React.FC<ButtonProps> = ({
  augmented,
  borderless,
  children,
  diminished,
  disabled,
  onClick,
  text,
  width,
}) => {
  const ref = React.createRef<HTMLButtonElement>();

  const buttonStyleX = {
    ...buttonStyle,
    ...diminished && diminishedStyle,
    ...disabled && disabledStyle,
    ...augmented && augmentedStyle,
    ...width && { width },
    ...borderless && {
      border: 'none',
    },
  };

  const content = children === undefined
    ? text
    : children;

  return (
    <button ref={ref} style={buttonStyleX} onClick={onClick}>
      {content}
    </button>
  );
};
