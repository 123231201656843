import React from 'react';

import { NodeCompact } from '../components';
import { IsoformNodeComponent, IsoformNodeProps } from '../isoform';
import { IsoformNode } from '../isoform/isoform-node';
import { CheckboxX, LabelX } from '../nodes/support';
import { flexCenterMixin, rem } from '../styles';

interface BooleanNodeProps extends IsoformNodeProps {
  placeholder?: string;
}

const rowContainerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  height: rem(2.5),
};

const checkboxContainerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  flex: 0,
};

const labelContainerStyle: React.CSSProperties = {
  ...flexCenterMixin,
  height: '100%',
  justifyContent: 'flex-start',
  paddingLeft: rem(1 / 2),
  flex: 1,
};

const labelStyle: React.CSSProperties = {
  padding: '0',
  margin: '0',
  marginBottom: '0',
  fontSize: rem(11 / 12),
  userSelect: 'none',
  cursor: 'pointer',
};

const BooleanNodeComponent: IsoformNodeComponent<BooleanNodeProps> = ({
  dataPath,
}) => {
  return (
    <NodeCompact dataPath={dataPath}>
      <div style={rowContainerStyle}>
        <div style={checkboxContainerStyle}>
          <CheckboxX dataPath={dataPath} />
        </div>
        <div style={labelContainerStyle}>
          <LabelX dataPath={dataPath} style={labelStyle} />
        </div>
      </div>
    </NodeCompact >
  );
};

export class BooleanNode extends IsoformNode {
  static nodeName = 'Boolean';
  static component = BooleanNodeComponent;
}
