import React, { useState } from 'react';

import { submitSearchParams } from '../../support';
import { LilX } from '../shared';

type ISODateString = string;
type MaybeDate = Date | string | undefined;
type ParamKey = string;
type RequestParams = Record<ParamKey, string>;

interface DateFilterProps {
  paramKey: ParamKey;
  params: RequestParams;
  label: string;
  min?: ISODateString;
  max?: ISODateString;
  placeholder?: ISODateString;
}

// -----------------------------------------------------------------------------

const date = (val: MaybeDate): Date => new Date(val || ''); // '' = <Invalid Date>
const dateEq = (a: MaybeDate, b: MaybeDate): boolean => date(a).getTime() === date(b).getTime();

// Convert a serialized iso date string (with UTC offset)
// into "html format" (localized, no offset) that can be used by datetime-local input elements.
// No offset information is lost if the resulting string is used only as a localized date.
function htmlDate (str?: ISODateString | Date): string | undefined {
  if (str === undefined) {
    return undefined;
  }

  try {
    const d = date(str);
    return (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString())
      .slice(0, -1); // remove the trailing 'Z'
  } catch {
    return undefined;
  }
}

// -----------------------------------------------------------------------------

export const DateFilter: React.FC<DateFilterProps> = ({
  label,
  max,
  min,
  paramKey,
  params,
  placeholder,
}) => {
  const initialValue = params[paramKey] || placeholder;

  const [value, setValue] = useState<string | undefined>(htmlDate(initialValue));
  const [hadInput, setHadInput] = useState<boolean>(false);

  const hasParamValue = dateEq(params[paramKey], value);
  const placeholding = !hasParamValue && dateEq(value, placeholder);

  function submit () {
    if (value === undefined) {
      return;
    }

    submitSearchParams({
      [paramKey]: date(value).toISOString(),
    });
  }

  // ----

  function handleBlur (_: React.FocusEvent<HTMLInputElement>) {
    if (hadInput) {
      submit();
    }
  }

  function handleChange (evt: React.ChangeEvent<HTMLInputElement>) {
    setValue(evt.target.value);
  }

  function handleInput (_: React.SyntheticEvent<HTMLInputElement>) {
    setHadInput(true);
  }

  function handleKeyDown (evt: React.KeyboardEvent<HTMLInputElement>) {
    if (evt.key === 'Enter') {
      submit();
    }
  }

  function handleClickReset () {
    submitSearchParams({
      [paramKey]: undefined,
    });
  }

  const resetButton = hasParamValue && (
    <LilX onClick={handleClickReset}/>
  );

  const classNames = [
    'DateFilter',
    placeholding && '_placeholding',
  ].filter(x => !!x).join(' ');

  return (
    <div className={classNames}>
      <label>{label}:&nbsp;</label>
      <input
        max={htmlDate(max)}
        min={htmlDate(min)}
        onBlur={handleBlur}
        onChange={handleChange}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        type='datetime-local'
        value={htmlDate(value)}
      />
      {resetButton}
    </div>
  );
};

// -----------------------------------------------------------------------------

export default DateFilter;
