/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';

import { useAppDispatch, useAppSelector, useNodeHandlers } from '../../hooks';
import { DataPath, dataPathNodeId } from '../../isoform';
import { $nodeProps, $nodeState, setDataPathValue } from '../../store';
import { focusedBorderMixin, rem } from '../../styles';

// -----------------------------------------------------------------------------

const checkboxStyle: React.CSSProperties = {
  margin: 0,
  padding: 0,
  border: '1px solid',
  height: rem(1),
  width: rem(1),
  cursor: 'pointer',
  outline: 'none',
};

// -----------------------------------------------------------------------------

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface CheckboxXProps extends InputProps {
  dataPath: DataPath;
}

export const CheckboxX: React.FC<CheckboxXProps> = ({
  dataPath,
}) => {
  const nodeId = dataPathNodeId(dataPath);

  const appDispatch = useAppDispatch();
  const { handleBlur, handleFocus } = useNodeHandlers(dataPath);
  const { required, readonly } = useAppSelector($nodeProps)(dataPath);
  const { value, focused } = useAppSelector($nodeState)(dataPath);

  const ref = React.createRef<HTMLInputElement>();

  const inputStyleX = {
    ...checkboxStyle,
    ...focused && focusedBorderMixin,
  };

  function handleChange (evt: React.ChangeEvent) {
    const value = (evt.currentTarget as HTMLInputElement).checked;
    appDispatch(setDataPathValue(dataPath, value));
  }

  const checked = !!value;

  return (
    <input
      type='checkbox'
      id={nodeId}
      name={nodeId}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      ref={ref}
      required={!!required}
      style={inputStyleX}
      checked={checked}
      readOnly={!!readonly}
    />
  );
};
