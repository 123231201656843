import { AsYouType, CountryCode } from 'libphonenumber-js';

import { DataValue, IsoformNode } from '../isoform';

export type Filter = (val: DataValue) => DataValue;
export type FilterCreator = (...args: RegExp[]) => Filter;

// -----------------------------------------------------------------------------

export function applyFilters (nodeType: typeof IsoformNode, val: DataValue): DataValue {
  return nodeType.filters.reduce((accu: DataValue, filter: Filter) => {
    return filter(accu);
  }, val);
}

// -----------------------------------------------------------------------------

// passes only characters that individually match the regex
export const charFilter: FilterCreator = (chars: RegExp) => {
  return function (val: DataValue) {
    return (val?.toString ? val.toString() : '')
      .split('')
      .reduce((accu: string[], char: string) => {
        if (char.match(chars)) {
          return [...accu, char];
        }
        return accu;
      }, [])
      .join('');
  };
};

// passes only characters that individually match the regex
export const phoneFilter = (countryCode = 'US'): Filter => {
  return function (val: DataValue) {
    return new AsYouType(countryCode as CountryCode).input((val || '').toString());
  };
};
