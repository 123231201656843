import { IsoformNode, NodeName } from '../isoform';
import { BooleanNode } from './BooleanNode';
import { CollectionNode } from './CollectionNode';
import { ContainerNode } from './ContainerNode';
import { DateNode } from './DateNode';
import { DateRangeNode } from './DateRangeNode';
import { DobNode } from './DobNode';
import { EmailNode } from './EmailNode';
import { FieldsetNode } from './FieldsetNode';
import { FreeFormTextNode } from './FreeFormTextNode';
import { LanguageNode } from './LanguageNode';
import { LegalAgreementNode } from './LegalAgreementNode';
import { OpenSelectNode } from './OpenSelectNode';
import { PhoneKindNode } from './PhoneKindNode';
import { PolarQuestionNode } from './PolarQuestionNode';
import { RowNode } from './RowNode';
import { SexNode } from './SexNode';
import { TextNode } from './TextNode';
import { UnknownNode } from './UnknownNode';
import { UsPhoneNumberNode } from './UsPhoneNumberNode';
import { UsStateNode } from './UsStateNode';
import { UsZipCodeNode } from './UsZipCodeNode';

export * from './BooleanNode';
export * from './CollectionNode';
export * from './ContainerNode';
export * from './DateNode';
export * from './DateRangeNode';
export * from './DobNode';
export * from './EmailNode';
export * from './FieldsetNode';
export * from './FreeFormTextNode';
export * from './LanguageNode';
export * from './LegalAgreementNode';
export * from './OpenSelectNode';
export * from './PhoneKindNode';
export * from './PolarQuestionNode';
export * from './RowNode';
export * from './SexNode';
export * from './TextNode';
export * from './UnknownNode';
export * from './UsPhoneNumberNode';
export * from './UsStateNode';
export * from './UsZipCodeNode';

export function findNode (nodeName: NodeName | undefined): IsoformNode | undefined {
  // For some unknown reason, the nodes list needs to be declared here inside the function scope
  // in order for `ContainerNode` to not be `undefined` during `nodes.find`.
  // I have no idea why this is the case, and I suspect some sort of bug in the bundling/transpiling.
  const nodes: IsoformNode[] = [
    BooleanNode,
    CollectionNode,
    ContainerNode,
    DateNode,
    DateRangeNode,
    DobNode,
    EmailNode,
    FieldsetNode,
    FreeFormTextNode,
    LanguageNode,
    LegalAgreementNode,
    OpenSelectNode,
    PhoneKindNode,
    PolarQuestionNode,
    RowNode,
    SexNode,
    TextNode,
    UnknownNode,
    UsPhoneNumberNode,
    UsStateNode,
    UsZipCodeNode,
  ];

  if (nodeName === undefined) {
    return undefined;
  }

  return nodes.find(n => {
    const klass = (n as typeof IsoformNode);
    return klass.nodeName === nodeName;
  });
}
