window.onload = function () {
  const clipboardComponents = document.querySelectorAll('.clipboard-component');

  clipboardComponents.forEach(cc => {
    let val = cc.dataset.value;

    cc.addEventListener('click', (event) => {
      navigator.clipboard.writeText(val);
      event.preventDefault();
    });
  });
}
