import React, { useContext } from 'react';

import { VisitPlanContext } from './VisitPlanContext';

export const VisitPlanTitle: React.FC = () => {
  const {
    caregiversAssigned,
  } = useContext(VisitPlanContext);

  const count = caregiversAssigned.length;

  return (
    <div className='VisitPlanTitle'>
      <h5>Caregivers ({count} Assigned)</h5>
    </div>
  );
};

export default VisitPlanTitle;
