/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';

import { useAppSelector } from '../../hooks';
import { DataPath, dataPathNodeId } from '../../isoform';
import { $nodeProps, $nodeValidation } from '../../store';
import { Palette, rem } from '../../styles';
import { isPresent } from '../../support';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface InputXProps extends InputProps {
  dataPath: DataPath;
  style?: React.CSSProperties;
  labelOverride?: string;
}

const labelStyle: React.CSSProperties = {
  fontSize: 'calc(1rem * (10/12))',
  margin: 0,
  padding: 0,
  marginBottom: rem(1 / 2),
  display: 'block',
};

export const LabelX: React.FC<InputXProps> = (props) => {
  const nodeId = dataPathNodeId(props.dataPath);

  const { hasProblems } = useAppSelector($nodeValidation)(props.dataPath);
  const { label } = useAppSelector($nodeProps)(props.dataPath);

  const labelStyleX = {
    ...labelStyle,
    ...props.style,
    ...hasProblems && {
      borderColor: Palette.Attention,
    },
  };

  const propsX = {
    ...props,
  } as InputProps;

  const labelX = isPresent(props.labelOverride) ? props.labelOverride : label;

  // @ts-ignore
  delete propsX.dataPath;
  // @ts-ignore
  delete propsX.labelOverride;

  return (
    <label htmlFor={nodeId} style={labelStyleX}>{labelX}</label>
  );
};
