import React from 'react';

interface NickelProps {
  label?: string;
  children?: React.ReactNode;
  solid?: boolean;
}

export const Nickel: React.FC<NickelProps> = ({
  label,
  children,
  solid,
}) => {
  const radius = 23 / 4;
  const dashCount = 16;
  const circumference = (radius * 2) * Math.PI;
  const lineLength = (circumference / dashCount) * (2 / 3);
  const gapLength = (circumference / dashCount) * (1 / 3);
  const strokeDasharray = [lineLength, gapLength].join(' ');
  const strokeWidth = 1 / 12;

  const plusGlyph = (label === undefined) && (
    <>
      <line x1='5' y1='6' x2='7' y2='6' stroke='#000000' strokeWidth={strokeWidth} />
      <line x1='6' y1='5' x2='6' y2='7' stroke='#000000' strokeWidth={strokeWidth} />
    </>
  );

  const circleProps = {
    r: radius,
    stroke: '#000',
    strokeWidth,
    strokeDasharray,
    fill: 'transparent',
    ...solid && {
      strokeDasharray: undefined,
      strokeWidth: strokeWidth * 3,
    },
  };

  return (
    <div className='Nickel'>
      <span className='label'>{label || ''}</span>
      {children}
      <div className='svg-container'>
        <svg width='100%' height='100%' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='6' cy='6' {...circleProps} />
          {plusGlyph}
        </svg>
      </div>
    </div>
  );
};

export default Nickel;
